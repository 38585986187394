import * as React from "react";
import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isMobile, isMobileOnly } from "react-device-detect";
import { showToast } from "../../../../config/helpers";
import ModalWrapper from "../../../../components/modalWrapper";
import Attach from "../../../../assets/iconsSmall/paper_clip.svg";
import Remove from "../../../../assets/iconsSmall/cross_black.svg";
import Upload from "../../../../assets/iconsSmall/upload_icon.svg";
import { Request } from "../../../../config/requests";
import { getToken } from "../../../../config/token";
import { Markup } from "interweave";
import CustomSelect from "../../../../components/inputs/select/CustomSelect";
import Loading from "../../../../components/common/Loading";
import { useNavigate } from "react-router-dom";
import Datepicker from "../../../../components/common/components/Datepicker";

function ModalPetData({
  openModalPetData,
  setOpenModalPetData,
  petType,
  // petInfo
}) {
  console.log("MCC >>> petType", petType)
  const token = getToken();
  const { t } = useTranslation();
  const router = useNavigate();
  const [selectedPetInfo, setSelectedPetInfo] = useState(null);
  // console.log("MCC >>> selectedPetInfo", selectedPetInfo)
  const [dataType, setDataType] = useState("");
  // console.log("MCC >>> dataType", dataType)
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const changeDataTypesDog = [
    { label: t("Pet name"), value: "Pet name" },
    { label: t("Pet breed"), value: "Pet breed" },
    { label: t("Pet birthday"), value: "Pet birthday" },
    { label: t("Pet chipnumber"), value: "Pet chipnumber" },
    { label: t("Pet gender"), value: "Pet gender" },
    { label: t("Dog height"), value: "Dog height" },
  ];
  const changeDataTypesCat = [
    { label: t("Pet name"), value: "Pet name" },
    { label: t("Pet breed"), value: "Pet breed" },
    { label: t("Pet birthday"), value: "Pet birthday" },
    { label: t("Pet chipnumber"), value: "Pet chipnumber" },
    { label: t("Pet gender"), value: "Pet gender" },
    { label: t("Cat habitat"), value: "Cat habitat" },
  ];
  const breedTypes = [
    { label: t("pureBreed"), value: "pure" },
    { label: t("hybridBeed"), value: "hybrid" },
    { label: t("unknownBeed"), value: "unknown" },
  ];
  const [dialog, setDialog] = useState("main");
  const [petName, setPetName] = useState("");
  const [petBreedType, setPetBreedType] = useState(null);
  const [petBreed, setPetBreed] = useState(null);
  const [petFirstBreed, setPetFirstBreed] = useState(null);
  const [petSecondBreed, setPetSecondBreed] = useState(null);
  const [petBD, setPetBD] = useState(null);
  const [petChip, setPetChip] = useState("");
  const [chipError, setChipError] = useState(false);
  const [petGender, setPetGender] = useState(null);
  const [dogHeight, setDogHeight] = useState(null);
  const [catHabitat, setCatHabitat] = useState(null);

  console.log("MPD --> dialog", dialog)
  console.log("MPD --> petName", petName)
  console.log("MPD --> petBD", petBD)

  const headerLabels = {
    "main": t("changeData"),
    "Pet name": t("petNameChange"),
    "Pet breed": t("petBreedChange"),
    "Pet birthday": t("petBDChange"),
    "Pet chipnumber": t("petChipChange"),
    "Pet gender": t("petGenderChange"),
    "Dog height": t("dogHeightChange"),
    "Cat habitat": t("catHabitatChange"),
  }

  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newFiles = Array.from(files).filter((file) => {
      return !selectedFiles.some((selectedFile) => selectedFile.name === file.name);
    });
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    const newFiles = Array.from(files).filter((file) => {
      return !selectedFiles.some((selectedFile) => selectedFile.name === file.name);
    });
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const isValidNumber = (str) => {
    return /^[0-9]{15}$/.test(str);
  }

  const handleChip = (e) => {
    const { value } = e.target;
    if (value.length <= 15) {
      if (isValidNumber(value)) {
        setPetChip(value);
        setChipError(false);
      } else {
        setPetChip(value);
        setChipError(true);
      }
    }
  };

  function handleClose() {
    setSelectedPetInfo(null);
    setDataType(null);
    setSelectedFiles([]);
    setDialog("main");
    setOpenModalPetData(false);
  }

  async function handleSubmit() {
    setSubmitting(true);
    setDialog("thankYouYes");
    const fd = new FormData();
    selectedFiles.forEach(i => fd.append('files', i))
    fd.append('customerEmail', selectedPetInfo?.quote?.customer?.email);
    fd.append('policyNo', selectedPetInfo?.policy?.policyNo);

    return Request({
      method: "post",
      route: "api/users/cancellation_request2",
      formData: fd,
    })
      .then(res => {
        if (!res.ok) throw new Error(`Request failed with status ${res.status}`);
      })
      .catch((err) => {
        showToast(t(err.message || "somethingWentWrong"), { error: true });
        router("/")
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  return (
    <ModalWrapper
      headerTitle={headerLabels[dataType?.value || dialog]}
      openModal={openModalPetData}
      setModalWrapper={() => handleClose()}
    >
      {dialog === "main" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "unset" }}>
            {t("whichPetDataToChange")}
          </div>
          <div className="modal_container__body__inputs" style={{ margin: "40px 0" }}>
            <CustomSelect
              variant="modal-cancel-contract"
              options={petType === "DOG" ? changeDataTypesDog : changeDataTypesCat}
              formatCreateLabel={(i) => i}
              placeholder={t("pleaseSelectHere")}
              value={dataType}
              onChange={(e) => setDataType(e)}
              isClearable={true}
            />
          </div>
          <div
            className={
              (dataType !== null)
                ? `button_red_small ${isMobileOnly && "mobile"}`
                : `button_red_smal_disabled ${isMobileOnly && "mobile"}`
            }
            onClick={(dataType !== null)
              ? () => setDialog(dataType.value)
              : ((e) => e.stopPropagation())}
          >
            {t("Next")}
          </div>
        </>}

      {dialog === "Pet breed" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "unset" }}>
            {t("pleaseSelectBreedType")}
          </div>
          <div className="modal_container__body__inputs">
            <div className="modal_container__body__radio">
              <label style={{ display: 'block', marginBottom: '8px' }}>
                <input
                  type="radio"
                  value="pure"
                  checked={petBreedType === 'pure'}
                  onChange={(e) => setPetBreedType(e.target.value)}
                  style={{ marginRight: '8px' }}
                />
                {t("pureBreed")}
              </label>
            </div>
            <div className="modal_container__body__radio">
              <label style={{ display: 'block', marginBottom: '8px' }}>
                <input
                  type="radio"
                  value="hybrid"
                  checked={petBreedType === 'hybrid'}
                  onChange={(e) => setPetBreedType(e.target.value)}
                  style={{ marginRight: '8px' }}
                />
                {t("hybridBeed")}
              </label>
            </div>
            <div className="modal_container__body__radio">
              <label style={{ display: 'block', marginBottom: '8px' }}>
                <input
                  type="radio"
                  value="Unbekannt"
                  checked={petBreedType === 'Unbekannt'}
                  onChange={(e) => setPetBreedType(e.target.value)}
                  style={{ marginRight: '8px' }}
                />
                {t("unknownBeed")}
              </label>
            </div>
            <div className="modal_container__body__inputs" style={{ margin: "20px 0" }}>
              {petBreedType === "pure" &&
                <CustomSelect
                  variant="modal-cancel-contract"
                  options={petType === "DOG" ? changeDataTypesDog : changeDataTypesCat}
                  formatCreateLabel={(i) => i}
                  placeholder={t("pleaseSelectHere")}
                  value={petFirstBreed}
                  onChange={(e) => setPetFirstBreed(e)}
                  isClearable={true}
                />}
              {petBreedType === "hybrid" &&
                <>
                  <CustomSelect
                    variant="modal-cancel-contract"
                    options={petType === "DOG" ? changeDataTypesDog : changeDataTypesCat}
                    formatCreateLabel={(i) => i}
                    placeholder={t("pleaseSelectHere")}
                    value={petFirstBreed}
                    onChange={(e) => setPetFirstBreed(e)}
                    isClearable={true}
                  />
                  <CustomSelect
                    variant="modal-cancel-contract"
                    options={petType === "DOG" ? changeDataTypesDog : changeDataTypesCat}
                    formatCreateLabel={(i) => i}
                    placeholder={t("pleaseSelectHere")}
                    value={petSecondBreed}
                    onChange={(e) => setPetSecondBreed(e)}
                    isClearable={true}
                  />
                </>}
            </div>
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""} ${petName ? "" : "disabled"}`}
              style={{ width: "unset" }}
              onClick={petName
                ? (() => setDialog("thankYouYes"))
                : ((e) => e.stopPropagation())}
            >
              {t("sendChange")}
            </div>
          </div>
        </>
      }

      {dialog === "Pet name" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "unset" }}>
            {t("pleaseEnterPetName")}
          </div>
          <div className="modal_container__body__inputs">
            <input
              className="modal_container__body__input"
              placeholder={t("name")}
              value={petName}
              onChange={(e) => setPetName(e.target.value)}
            />
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""} ${petName ? "" : "disabled"}`}
              style={{ width: "unset" }}
              onClick={petName
                ? (() => setDialog("thankYouYes"))
                : ((e) => e.stopPropagation())}
            >
              {t("sendChange")}
            </div>
          </div>
        </>
      }

      {dialog === "Pet chipnumber" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "unset" }}>
            {t("pleaseEnterPetChip")}
          </div>
          <div className="modal_container__body__inputs">
            <input
              className="modal_container__body__input"
              placeholder={t("chipHere")}
              value={petChip}
              onChange={handleChip}
              style={{
                border: chipError ? "4px solid var(--b-color-main-2)" : "",
              }}
            />
            {chipError &&
              <div className="modal_container__body__error FastShow">
                {t("chipWrong")}
              </div>}
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""} ${petChip ? "" : "disabled"}`}
              style={{ width: "unset" }}
              onClick={petChip
                ? (() => setDialog("attachFiles"))
                : ((e) => e.stopPropagation())}
            >
              {t("sendChange")}
            </div>
          </div>
        </>
      }

      {dialog === "Pet birthday" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "unset" }}>
            {t("pleaseEnterPetBD")}
          </div>
          <div className="modal_container__body__dropdowns">
            <Datepicker
              date={petBD}
              setDate={(newValue) => setPetBD(newValue)}
              disabled={false}
              type={"reset-dp-modal"}
            />
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""} ${petBD ? "" : "disabled"}`}
              style={{ width: "unset" }}
              onClick={petBD
                ? (() => setDialog("attachFiles"))
                : ((e) => e.stopPropagation())}
            >
              {t("sendChange")}
            </div>
          </div>
        </>
      }

      {dialog === "Pet gender" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "unset" }}>
            {t("pleaseSelectPetGender")}
          </div>
          <div className="modal_container__body__inputs">
            <div className="modal_container__body__row-modal">
              <div
                className={`modal_container__body__button-modal ${petGender === "male" ? "" : "disabled"}`}
                onClick={() => setPetGender("male")}
              >
                {t("male")}
              </div>
              <div
                className={`modal_container__body__button-modal  ${petGender === "female" ? "" : "disabled"}`}
                onClick={() => setPetGender("female")}
              >
                {t("female")}
              </div>
            </div>
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""} ${petGender ? "" : "disabled"}`}
              style={{ width: "unset" }}
              onClick={petGender
                ? (() => setDialog("thankYouYes"))
                : ((e) => e.stopPropagation())}
            >
              {t("sendChange")}
            </div>
          </div>
        </>
      }

      {dialog === "Dog height" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "unset" }}>
            {t("pleaseSelectDogHeight")}
          </div>
          <div className="modal_container__body__inputs">
            <div className="modal_container__body__row-modal">
              <div
                className={`modal_container__body__button-modal ${dogHeight === "0 - 44 cm" ? "" : "disabled"}`}
                onClick={() => setDogHeight("0 - 44 cm")}
              >
                0 - 44 cm
              </div>
              <div
                className={`modal_container__body__button-modal  ${dogHeight === "> 45 cm" ? "" : "disabled"}`}
                onClick={() => setDogHeight("> 45 cm")}
              >
                &gt; 45 cm
              </div>
            </div>
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""} ${dogHeight ? "" : "disabled"}`}
              style={{ width: "unset" }}
              onClick={dogHeight
                ? (() => setDialog("dogHeightConfirm"))
                : ((e) => e.stopPropagation())}
            >
              {t("sendChange")}
            </div>
          </div>
        </>
      }

      {dialog === "dogHeightConfirm" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "540px" }}>
            {t("changeHeightMessage")}
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""}`}
              onClick={() => setDialog("thankYouYes")}
            >
              {t("Yes")}
            </div>
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""}`}
              onClick={() => setDialog("thankYouNo")}
            >
              {t("No")}
            </div>
          </div>
        </>
      }

      {dialog === "Cat habitat" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "unset" }}>
            {t("pleaseSelectCatHabitat")}
          </div>
          <div className="modal_container__body__inputs">
            <div className="modal_container__body__row-modal">
              <div
                className={`modal_container__body__button-modal ${catHabitat === "domestic" ? "" : "disabled"}`}
                onClick={() => setCatHabitat("domestic")}
              >
                {t("domestic")}
              </div>
              <div
                className={`modal_container__body__button-modal  ${catHabitat === "outdoor" ? "" : "disabled"}`}
                onClick={() => setCatHabitat("outdoor")}
              >
                {t("outdoor")}
              </div>
            </div>
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""} ${catHabitat ? "" : "disabled"}`}
              style={{ width: "unset" }}
              onClick={catHabitat
                ? (() => setDialog("dogHeightConfirm"))
                : ((e) => e.stopPropagation())}
            >
              {t("sendChange")}
            </div>
          </div>
        </>
      }

      {dialog === "catHabitatConfirm" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "540px" }}>
            {t("changeHabitatMessage")}
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""}`}
              onClick={() => setDialog("thankYouYes")}
            >
              {t("Yes")}
            </div>
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""}`}
              onClick={() => setDialog("thankYouNo")}
            >
              {t("No")}
            </div>
          </div>
        </>
      }

      {dialog === "attachFiles" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "540px" }}>
            {t("pleaseAttach")}
          </div>
          <div
            className="modal_container__body__uploader"
            style={{ maxWidth: "540px" }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={handleButtonClick}
          >
            <input
              type="file"
              ref={fileInputRef}
              // accept=".jpg,.png"
              onChange={handleFileChange}
              hidden
              multiple
            />
            <img src={Upload} />
            <span>{t("docUploadPlaceholder")}</span>
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""}`}
              style={{ width: "unset", padding: isMobile ? "0 20px" : "0 39px" }}
            >
              {t("uploadDocument")}
            </div>
          </div>
          {selectedFiles.length
            ? <div className="modal_container__body__text" style={{ maxWidth: "540px", margin: "0 0 15px 0" }}>
              {t("uploadedDocuments")}
            </div>
            : null}
          {selectedFiles.length
            ? selectedFiles.map((file, ind) => (
              <div key={ind} className="modal_container__body__attach" style={{ maxWidth: "440px", margin: "0 0 20px 0" }}>
                <img
                  src={Attach}
                  className="pointer"
                  width={isMobile ? 30 : 40}
                  alt="Attach"
                />
                <input
                  readOnly
                  className="modal_container__body__input-file"
                  placeholder={t("addDocument")}
                  value={file.name || ""}
                />
                <img
                  src={Remove}
                  alt="Remove"
                  style={{
                    position: "absolute",
                    right: "12px",
                    cursor: "pointer",
                  }}
                  onClick={() => setSelectedFiles((prevSelectedFiles) => prevSelectedFiles.filter(el => el.name !== file.name))}
                />
              </div>
            ))
            : null}
          <div className="modal_container__body__row-modal" style={{ margin: "10px 0 0 0" }}>
            <div
              className={
                (selectedFiles.length)
                  ? `button_red_small ${isMobileOnly ? "mobile" : ""}`
                  : `button_red_smal_disabled ${isMobileOnly ? "mobile" : ""}`
              }
              onClick={(selectedFiles.length)
                ? (() => handleSubmit())
                : ((e) => e.stopPropagation())}
            >
              {t("Next")}
            </div>
          </div>
        </>}

      {dialog === "thankYouYes" &&
        <>
          {submitting
            ? <Loading noText fast />
            : <>
              <div
                className="modal_container__body__text"
                style={{
                  maxWidth: "540px",
                  margin: "0 0 30px 0",
                  textAlign: "start"
                }}
              >
                <Markup content={t("thankYouYesMessage")} />
              </div>
              <div className="modal_container__body__row-modal">
                <div
                  className={`button_red_small ${isMobileOnly ? "mobile" : ""}`}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  OK
                </div>
              </div>
            </>}
        </>
      }

      {dialog === "thankYouNo" &&
        <>
          <div
            className="modal_container__body__text"
            style={{
              maxWidth: "540px",
              margin: "0 0 30px 0",
              textAlign: "start"
            }}
          >
            <Markup content={t("thankYouNoMessage")} />
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""}`}
              onClick={() => {
                handleClose();
              }}
            >
              OK
            </div>
          </div>
        </>
      }

    </ModalWrapper>
  );
}

export default ModalPetData;
