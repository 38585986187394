import { Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { Request } from "../../config/requests";
import { getToken } from "../../config/token";
import { useTranslation } from "react-i18next";
import InitialLanguage from "../flowManagement/components/InitialLanguage";

export default function DropdownModal({
  open,
  handleClose,
  isEdit = false,
  fetchData,
  currentRow,
}) {
  const { t, i18n } = useTranslation();
  const [dropDown, setDropDown] = useState(
    currentRow
      ? currentRow
      : { name: "", options: [], translations: { initial_language: "" } }
  );
  const [option, setOption] = useState("");
  const [language, setLanguage] = useState(
    currentRow?.translations?.initial_language
      ? currentRow?.translations?.initial_language
      : i18n.language
  );

  const onAddOption = () => {
    if (dropDown.options.some((i) => i === option) || option === "") return;
    setDropDown({ ...dropDown, options: [...dropDown.options, option] });
    setOption("");
  };

  const onDeleteOption = (option) => {
    setDropDown({
      ...dropDown,
      options: dropDown.options.filter((i) => i !== option),
    });
  };

  const handleChangeLang = useCallback(({ target }) => {
    setLanguage(target.value);
  }, []);

  const onSave = async () => {
    if (!dropDown.name || !dropDown.options.length) return;
    try {
      if (isEdit) {
        await Request({
          method: "patch",
          token: getToken(),
          route: `api/dropdowns/${currentRow.id}`,
          values: {
            ...dropDown,
            translations: {
              ...dropDown?.translations,
              initial_language: language,
            },
          },
        });
      } else
        await Request({
          method: "post",
          token: getToken(),
          route: `api/dropdowns`,
          values: {
            ...dropDown,
            translations: {
              ...dropDown?.translations,
              initial_language: language,
            },
          },
        });
      handleClose();
      fetchData();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {isEdit ? t("editDropdown") : t("createDropdown")}
      </DialogTitle>
      <DialogContent className="dialog-content">
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label={t("name")}
          value={dropDown.name}
          onChange={({ target }) =>
            setDropDown({ ...dropDown, name: target.value })
          }
          type="text"
          fullWidth
          variant="standard"
        />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            margin="dense"
            value={option}
            onChange={({ target }) => setOption(target.value)}
            id="option"
            label="Option"
            type="text"
            fullWidth
            variant="standard"
          />
          <button className="ButtonReverse Enabled" onClick={onAddOption}>
            {t("add")}
          </button>
        </Box>
        <DialogContentText sx={{ marginTop: "1rem" }}>
          {t("Options")}
        </DialogContentText>

        <List dense>
          {dropDown.options.map((i, ind) => (
            <ListItem
              key={ind}
              secondaryAction={
                <IconButton
                  onClick={() => onDeleteOption(i)}
                  edge="end"
                  aria-label="delete"
                >
                  <Delete />
                </IconButton>
              }
            >
              <ListItemText primary={i} />
            </ListItem>
          ))}
        </List>
        <InitialLanguage
          language={language}
          handleChangeLang={handleChangeLang}
          options={{ marginLeft: 0, marginTop: "1rem" }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("Cancel")}</Button>
        <button className="ButtonReverse Enabled" onClick={onSave}>
          {t("save")}
        </button>
      </DialogActions>
    </Dialog>
  );
}
