import React, { useEffect } from "react";
import FlowStepsAdd from "./FlowStepsAdd";
import FlowStepsItem from "./FlowStepsItem";

const FlowContainer = ({
  themeSetting,
  subFlow,
  subFlowId,
  steps,
  setSteps,
  passContentToParent,
  openOptions,
  toggleOptions,
  listDiagnosis,
  gotDetails,
  isFinished,
  isAllFilled,
  isLastStepMCQ,
  isLastStepStatus,
  FlowSteps,
  onArrowUpdate,
}) => {
  const onDeleteMCQOption = (index, idItem) => {
    setSteps(
      [].concat.apply(
        [],
        [
          steps.slice(0, idItem),
          {
            ...steps[idItem],
            content: steps[idItem].content.filter((e, idx) => idx !== index),
            options: steps[idItem].options.filter((e, idx) => idx !== index),
          },
          steps.slice(idItem + 1, steps.length),
        ]
      )
    );
  };

  useEffect(() => {
    onArrowUpdate({ steps, subFlow, subFlowId });
  }, [steps]);

  return (
    <React.Fragment>
      {(steps.length > 0 || subFlow) &&
        steps.map((step, idx) => (
          // ONE STEP
          <div
            key={`flow-${step.uniqueId}`}
            className="FlowHolder"
            style={{ width: !subFlow ? "100%" : "" }}
          >
            <div
              id={
                subFlowId
                  ? `items-holder-${idx}-${subFlowId}`
                  : `items-holder-${idx}`
              }
              className="FSItemsHolder"
              style={{ boxSizing: "border-box" }}
            >
              {/* DIAGNOSIS || GOT options */}
              <div id={`step-${idx}`}>
                {!step.code &&
                  step.map((sub_step) => (
                    <FlowStepsItem
                      idItem={idx}
                      key={`sub_step-${sub_step.uniqueId}`}
                      themeSetting={themeSetting}
                      step={sub_step}
                      steps={steps}
                      setSteps={setSteps}
                      onDeleteMCQOption={onDeleteMCQOption}
                    />
                  ))}

                {/* OTHER OPTIONS */}
                {step.code && (
                  <FlowStepsItem
                    idItem={idx}
                    key={`step-${step.uniqueId}`}
                    themeSetting={themeSetting}
                    step={step}
                    steps={steps}
                    setSteps={setSteps}
                    onDeleteMCQOption={onDeleteMCQOption}
                  />
                )}
              </div>
            </div>

            {/* SUBFLOW */}
            {step.code === "mcq" && (
              <div
                className="SubflowHolder"
                style={{
                  justifyContent:
                    step.content.length < 2 ? "center" : "space-between",
                  // width: step.options.length < 2 ? "fit-content" : "100%",
                  width: "100%",
                }}
              >
                {step.options.map((question, q) => (
                  <>
                    <div
                      className="FSItemsHolder"
                      style={{
                        // width: "300px",
                        border: "none",
                        boxSizing: "border-box",
                        boxShadow: "none",
                        background: "var(--background)",
                        marginLeft:
                          step.options.length < 2 || !q ? "0" : "20px",
                      }}
                      id={
                        subFlowId
                          ? `q-${idx}-${q}-${subFlowId}`
                          : `q-${idx}-${q}`
                      }
                      key={`q-${step.uniqueId}-${q}`}
                    >
                      <div className="SFTitle">{question.name}</div>

                      <FlowSteps
                        themeSetting={themeSetting}
                        key={step?.content[q]?.uniqueId}
                        subFlow
                        subFlowId={
                          subFlowId
                            ? `q-${idx}-${q}-${subFlowId}`
                            : `q-${idx}-${q}`
                        }
                        autoFill={step?.content[q]?.content}
                        passContentUp={passContentToParent}
                        question={question}
                      />
                    </div>
                  </>
                ))}
              </div>
            )}
          </div>
        ))}

      {!isFinished && isAllFilled && !isLastStepMCQ && !isLastStepStatus && (
        <FlowStepsAdd
          steps={steps}
          setSteps={setSteps}
          openOptions={openOptions}
          toggleOptions={toggleOptions}
          subFlow={subFlow}
          listDiagnosis={listDiagnosis}
          gotDetails={gotDetails}
        />
      )}
    </React.Fragment>
  );
};

export default FlowContainer;
