import { useCallback, useState } from "react";
import { Request } from "../config/requests";

export default function useAttchFiles({ claimId }) {
  const [isLoading, setIsLoading] = useState(false);

  const upload = useCallback(
    async ({ files }) => {
      const fd = new FormData();
      files.forEach(i => fd.append('files', i))
      try {
        setIsLoading(true);
        return await Request({
          method: "post",
          route: `api/v2/claims/attach_files/${claimId}`,
          formData: fd,
        });
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    },
    [claimId]
  );

  return { upload, isLoading };
}
