import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Request } from "../../config/requests";
import { getToken } from "../../config/token";
import FlowStepsConfirm from "./components/FlowStepsConfirm";
import Loading from "../../components/common/Loading";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import FlowContainer from "./components/FlowContainer";
import FlowArrow from "./components/FlowArrow";
import uuidv4 from "react-uuid";
import { assignUniqueKey } from "../../config/helpers";
import { useDispatch, useSelector } from "react-redux";
import { useXarrow, Xwrapper } from "react-xarrows";
import {
  clearArrows,
  updateArrows,
  updateDropdowns,
  updateFlowGroup,
  updateFlowSteps,
} from "../../config/actions";
import isEqual from "lodash.isequal";
import useLocalStorage from "use-local-storage";
import { useTranslation } from "react-i18next";
import FlowGroup from "./FlowGroup";
import randomColor from "randomcolor";
import InitialLanguage from "./components/InitialLanguage";
import { useThrottleEffect } from "../../hooks/useThrottleEffect";

const FlowSteps = ({
  subFlow,
  subFlowId,
  passContentUp,
  question,
  autoFill,
}) => {
  const history = useNavigate();
  const updateXarrow = useXarrow();
  const dispatch = useDispatch();
  const [themeSetting] = useLocalStorage("theme", "panda");
  const { t, i18n } = useTranslation();

  const arrows = useSelector((s) => s.flow.arrows);
  const flowGroup = useSelector((s) => s.flow.isFlowGroup);
  const isSameTitle = useSelector((s) => s.flow.isSameTitle);

  const [steps, setSteps] = useState(subFlow ? (autoFill ? autoFill : []) : []);
  const [openOptions, setOpenOptions] = useState(false);

  const [isFinished, setIsFinished] = useState(false);
  const [isAllFilled, setIsAllFilled] = useState(false);
  const [isLastStepMCQ, setIsLastStepMCQ] = useState(false);
  const [isLastStepStatus, setIsLastStepStatus] = useState(false);
  const [zoomType, setZoomType] = useState("edit");
  const [verifyError, setVerifyError] = useState({
    hasError: false,
    message: "",
  });
  const oldVerifyValues = useRef({});
  const initVerifyValues = useRef({});

  const [isLoading, setIsLoading] = useState(false);

  const [listDiagnosis, setListDiagnosis] = useState([]);
  const [gotDetails, setGotDetails] = useState([]);
  const [language, setLanguage] = useState([]);
  const [translations, setTranslations] = useState({});

  const { flow_id } = useParams();

  const handleChangeLang = useCallback(({ target }) => {
    setLanguage(target.value);
  }, []);

  const getDiagnoses = () => {
    setIsLoading(true);

    Request({ method: "get", route: "api/diagnosis" })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        setListDiagnosis(json.diagnosis);
      })
      .finally(() => setIsLoading(false));
  };

  const getGOT = () => {
    setIsLoading(true);

    Request({ method: "get", route: "api/claims/got" })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        setGotDetails(json.got);
      })
      .finally(() => setIsLoading(false));
  };

  const getDropdowns = () => {
    Request({ method: "get", route: "api/dropdowns" })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        dispatch(updateDropdowns(json.data || []));
      });
  };

  const getFlow = () => {
    setIsLoading(true);
    Request({ method: "get", token: getToken(), route: `api/flows/${flow_id}` })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        const newSteps = assignUniqueKey([...json.data.autofill]);
        const diagnosis = Array.isArray(newSteps[1])
          ? newSteps[1]?.find((i) => i.code === "diagnosis")
          : {};
        const gots = Array.isArray(newSteps[1])
          ? newSteps[1]?.find((i) => i.code === "got")
          : {};
        oldVerifyValues.current = {
          diagnosis: diagnosis ? diagnosis.value : [],
          gots: gots ? gots.value : [],
        };
        initVerifyValues.current = oldVerifyValues.current;
        setSteps([...newSteps]);
        setLanguage(json?.data?.translations?.initial_language);
        setTranslations(json?.data?.translations?.translations || {});
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const goBack = () => history(-1);

  const toggleOptions = () => {
    setOpenOptions(!openOptions);
  };

  const handleClickOutside = () => openOptions && setOpenOptions(!openOptions);

  const passContentToParent = (stepsPassed, questionPassed) => {
    const step = steps.find((item) => item.code === "mcq");

    if (step) {
      let sub_step = step.content.find(
        (sub_item) => sub_item.choice === questionPassed.name
      );

      const color = randomColor({
        luminosity: "light",
        hue: "blue",
      });

      if (sub_step) {
        sub_step.choice = questionPassed.name;
        sub_step.content = [...stepsPassed];
        sub_step.color = color;
      } else {
        step["content"].push({
          choice: questionPassed.name,
          content: [...stepsPassed],
          uniqueId: uuidv4(),
          color,
        });
      }
      setSteps([...steps]);
    }
  };

  const checkFlow = useCallback((steps) => {
    const last = steps[steps?.length - 1];

    if (last?.code === "mcq") {
      if (!last.content.length) return false;
      return last.content.every((i) => checkFlow(i.content));
    }
    return !!(last?.code === "status" && last?.value);
  }, []);

  const verify = useCallback(async () => {
    const diagnosis = steps[1]?.find((i) => i.code === "diagnosis");
    const gots = steps[1]?.find((i) => i.code === "got");
    const current = {
      diagnosis: diagnosis ? diagnosis.value : [],
      gots: gots ? gots.value : [],
    };

    if (isEqual(current, initVerifyValues.current)) {
      setVerifyError({
        hasError: true,
        message: "Diagnosis or GOT not changed",
      });
      return;
    }

    if (isEqual(current, oldVerifyValues.current)) {
      return;
    }
    oldVerifyValues.current = current;
    const res = await Request({
      method: "post",
      route: `api/flows/verify`,
      values: current,
      token: getToken(),
    });
    const results = await res.json();
    if (res.status === 400 && results.flow?.id !== flow_id) {
      setVerifyError({ hasError: true, message: results.data });
    } else {
      setVerifyError({ hasError: false, message: "" });
    }
  }, [flow_id, steps]);

  const setUploadOcrValue = useCallback((steps, isOcr = false) => {
    return steps.map((i) => {
      if (i.code === "upload" && !isOcr) {
        isOcr = i.ocr;
      } else if (i.code === "mcq") {
        i.content = i.content.map((j) => {
          j.content = setUploadOcrValue(j.content, isOcr);
          return j;
        });
      } else {
        i.isOcr = isOcr;
      }
      return i;
    });
  }, []);

  useThrottleEffect(() => {
    setIsFinished(checkFlow(steps));

    setIsLastStepMCQ(steps[steps.length - 1]?.code === "mcq" ? true : false);
    setIsLastStepStatus(
      steps[steps.length - 1]?.code === "status" ? true : false
    );

    const not_filled_step = steps
      .filter(
        (i) =>
          ![
            "new_claim",
            "trigger",
            "type",
            "existing_claim",
            "diagnosis",
            "invoice_table",
          ].includes(i.code)
      )
      .filter((step) => !step.value && !step.title && step.code);

    if (not_filled_step.length === 0) {
      setIsAllFilled(true);
    } else {
      setIsAllFilled(false);
    }

    if (subFlow) passContentUp(steps, question);
    if (!subFlow && steps) {
      if (steps[1]?.length) verify();
      setUploadOcrValue(steps);
      dispatch(updateFlowSteps(steps));
    }
  }, [
    checkFlow,
    dispatch,
    passContentUp,
    question,
    setUploadOcrValue,
    steps,
    subFlow,
    verify,
  ]);

  useEffect(() => {
    if (!steps[steps.length - 1]?.code && !subFlow) {
      getDiagnoses();
      getGOT();
    }

    if (flow_id && !subFlow) {
      getFlow();
    }
    if (!flow_id && !subFlow) {
      setLanguage(i18n.language);
    }
    if (!subFlow) {
      dispatch(clearArrows());
      getDropdowns();
    }
  }, []);

  const onArrowUpdate = (arrow) => {
    setTimeout(() => {
      dispatch(updateArrows(arrow));
    }, 250);
  };

  return (
    <>
      {isLoading && (
        <div className="FMContainerLoading">
          <Loading fast noText />
        </div>
      )}
      {!subFlow && flowGroup ? (
        <FlowGroup />
      ) : (
        <Xwrapper>
          {!isLoading && zoomType === "zoom" && (
            <div className="ContainerFlow">
              <TransformWrapper
                initialScale={1}
                minScale={0.2}
                maxScale={1}
                limitToBounds={false}
                panning={{ excluded: ["input"] }}
                onTransformed={() => updateXarrow()}
              >
                {({
                  zoomIn,
                  zoomOut,
                  resetTransform,
                }) => (
                  <React.Fragment>
                    {!subFlow && (
                      <div>
                        <div className="FMHeader-wrapper FMHeader-wrapper_mobile">
                          <div className="FMHeader">{t("createFlow")}</div>
                          <InitialLanguage
                            language={language}
                            handleChangeLang={handleChangeLang}
                          />
                        </div>
                        <div>
                          <div className="ButtonsGroupTopRight Top25">
                            <button
                              className="ButtonReverse Enabled FullWidth"
                              onClick={goBack}
                            >
                              {t("goBack")}
                            </button>
                            <FlowStepsConfirm
                              steps={steps}
                              language={language}
                              translations={translations}
                              disabled={
                                !isAllFilled ||
                                !isFinished ||
                                subFlow ||
                                verifyError.hasError ||
                                isSameTitle
                              }
                              message={
                                isSameTitle
                                  ? "Question titles can not be same"
                                  : verifyError.message
                              }
                            />
                            <button
                              className="ButtonReverse Enabled FullWidth"
                              onClick={() => {
                                setZoomType("edit");
                                dispatch(clearArrows());
                              }}
                            >
                              {t("editView")}
                            </button>
                          </div>
                          <div className="ButtonsGroupTopRight ZoomFixed">
                            <div className="ZoomTitle">{t("zoom")}</div>
                            <div className="flex">
                              <button
                                className="ButtonReverse Enabled"
                                onClick={() => zoomIn()}
                              >
                                +
                              </button>
                              <button
                                className="ButtonReverse Enabled"
                                onClick={() => zoomOut()}
                              >
                                -
                              </button>
                              <button
                                className="ButtonReverse Enabled"
                                onClick={() => resetTransform()}
                              >
                                x
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <TransformComponent>
                      <div className="ContainerFlow">
                        <FlowContainer
                          themeSetting={themeSetting}
                          subFlow={subFlow}
                          subFlowId={subFlowId}
                          steps={steps}
                          setSteps={setSteps}
                          passContentToParent={passContentToParent}
                          handleClickOutside={handleClickOutside}
                          openOptions={openOptions}
                          toggleOptions={toggleOptions}
                          listDiagnosis={listDiagnosis}
                          gotDetails={gotDetails}
                          isFinished={isFinished}
                          isAllFilled={isAllFilled}
                          isLastStepMCQ={isLastStepMCQ}
                          isLastStepStatus={isLastStepStatus}
                          onArrowUpdate={onArrowUpdate}
                          FlowSteps={FlowSteps}
                        />
                      </div>
                    </TransformComponent>
                  </React.Fragment>
                )}
              </TransformWrapper>
            </div>
          )}
          {!isLoading && zoomType === "edit" && (
            <>
              {!subFlow && (
                <div>
                  <div className="FMHeader-wrapper FMHeader-wrapper_mobile">
                    <div className="FMHeader">{t("createFlow")}</div>
                    <InitialLanguage
                      language={language}
                      handleChangeLang={handleChangeLang}
                    />
                  </div>
                  <div>
                    <div className="ButtonsGroupTopRight Top25">
                      <button
                        className="ButtonReverse Enabled FullWidth"
                        onClick={goBack}
                      >
                        {t("goBack")}
                      </button>
                      <FlowStepsConfirm
                        steps={steps}
                        language={language}
                        translations={translations}
                        disabled={
                          !isAllFilled ||
                          !isFinished ||
                          subFlow ||
                          verifyError.hasError ||
                          isSameTitle
                        }
                        message={
                          isSameTitle
                            ? "Question titles can not be same"
                            : verifyError.message
                        }
                      />
                      <button
                        className="ButtonReverse Enabled FullWidth"
                        onClick={() => {
                          setZoomType("zoom");
                          dispatch(clearArrows());
                          window.scrollTo(0, 0);
                        }}
                      >
                        {t("zoomView")}
                      </button>
                      <button
                        className="ButtonReverse Enabled FullWidth"
                        onClick={() => {
                          console.log("steps in s:", steps);
                          dispatch(updateFlowGroup(true));
                          dispatch(updateFlowSteps(steps));
                        }}
                      >
                        {t("group")}
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <FlowContainer
                themeSetting={themeSetting}
                subFlow={subFlow}
                subFlowId={subFlowId}
                steps={steps}
                setSteps={setSteps}
                passContentToParent={passContentToParent}
                handleClickOutside={handleClickOutside}
                openOptions={openOptions}
                toggleOptions={toggleOptions}
                listDiagnosis={listDiagnosis}
                gotDetails={gotDetails}
                isFinished={isFinished}
                isAllFilled={isAllFilled}
                isLastStepMCQ={isLastStepMCQ}
                isLastStepStatus={isLastStepStatus}
                FlowSteps={FlowSteps}
                onArrowUpdate={onArrowUpdate}
              />
            </>
          )}
          {!isLoading &&
            !subFlow &&
            arrows.map((i, ind) => (
              <FlowArrow
                key={ind}
                subFlow={i.subFlow}
                subFlowId={i.subFlowId}
                steps={i.steps}
              />
            ))}
        </Xwrapper>
      )}
    </>
  );
};

export default FlowSteps;
