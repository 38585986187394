import { Box, FormControl, Input, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import FormButtonFM from "./FormButtonFM";
import QuestionContainer from "./QuestionContainer";
import { useSelector } from "react-redux";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import useTranslations from "../../../../hooks/useTranslations";
import PetImage from "../../../common/components/PetImage";

function OQ({
  data,
  setData,
  details,
  showButtons = true,
  onUpdate,
  width,
  single = false,
}) {
  const [answer, setAnswer] = useState(details.answer || "");
  const { getTranslatedText } = useTranslations();
  const defaultValues = useSelector((s) => s.flow.uploadValues);

  useEffect(() => {
    const values = defaultValues[details.uploadId]?.details;
    if (details.isOcr && values && !details.answer) {
      const value = values[details.defaultValue]?.content;
      setAnswer(value || "");
      details.answer = value;
    }
  }, [defaultValues, details]);

  useEffect(() => {
    const ind = data.manual.findIndex((i) => i.name === details.title);
    const d = data.manual[ind];
    data.manual.splice(ind, 1);
    setData({ ...data, manual: [...data.manual, { ...d, value: answer }] });
  }, [answer]);

  const handleAnswer = (e) => {
    setAnswer(e.target.value);

    details["answer"] = e.target.value;
    let item = data.manual?.find((i) => i.name === details.title);
    if (item) {
      setData({
        ...data,
        manual: data.manual.map((i) =>
          i.name === details.title ? { ...i, value: details.answer } : i
        ),
      });
    } else {
      item = {
        name: details.title,
        value: details.answer,
      };
      setData({
        ...data,
        manual: [...data.manual, item],
      });
    }
  };

  return (
    <QuestionContainer width={width}>
      <Box style={{ width: "100%" }}>
        <Box className="flow-item-wrapper flow-content-spacing">
          <Box className="flow-item-container">
            <div
              className="TitleQuestion"
              style={{ padding: single ? "0 20px" : "" }}
            >
              {getTranslatedText(details.title)}
              {details.description && (
                <div className="TooltipGap">
                  <Tooltip
                    title={getTranslatedText(details.description)}
                    placement="right"
                  >
                    <InfoOutlinedIcon fontSize="small" />
                  </Tooltip>
                </div>
              )}
            </div>
            <div
              className="AnswersContainer"
              style={{ padding: single ? "0 20px" : "" }}
            >
              <div className="BasicInput text-input">
                <FormControl
                  sx={{
                    padding: "0 !important",
                    height: "56px !important",
                    width: "100%",
                  }}
                >
                  <Input
                    id="demo-multiple-name-title"
                    className={
                      answer === "" ? "OQInput BasicInput__error" : "OQInput"
                    }
                    type="text"
                    value={answer}
                    onChange={(e) => handleAnswer(e)}
                    sx={{
                      margin: "0 !important",
                      height: "100% !important",
                      padding: "0 !important",
                    }}
                  />
                </FormControl>
              </div>
            </div>
            {showButtons && (
              <div
                className="NavBtnsContainer"
                style={{ padding: single ? "0 20px" : "" }}
              >
                <FormButtonFM
                  name="Back"
                  route="back"
                />
                <FormButtonFM
                  name="Next"
                  route="next"
                  data={data}
                  onUpdate={onUpdate}
                  disabled={!answer}
                />
              </div>
            )}
          </Box>
          {single && <PetImage />}
        </Box>
      </Box>
    </QuestionContainer>
  );
}

export default OQ;
