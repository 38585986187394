import React, { useState } from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import TooltipArrowRight from "../../assets/iconsSmall/tooltip_red_arrow_right.svg";
import Question from "../../assets/iconsSmall/red_question.svg";
import Exclamation from "../../assets/iconsSmall/red_exclamation.svg";
import { isMobile, isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import TooltipContent from "./TooltipContent";


const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  // interactive={true}
  />
))`
  & .MuiTooltip-tooltip {
    ${({ placement }) =>
    placement === "right-start"
      ? `margin: 0 0 0 65px !important;
        padding: 20px;
        border-radius: 0 20px 20px 20px;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        font-variant-numeric: lining-nums proportional-nums;
        background-color: var(--b-color-main-1) !important;`
      : `margin: 15px 0 0 20px !important;
        padding: 20px;
        border-radius: 20px;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        font-variant-numeric: lining-nums proportional-nums;
        background-color: var(--b-color-additional-1) !important;`};
  }

  & .MuiTooltip-arrow {
    ${({ placement }) =>
    placement === "right-start"
      ? `color: transparent;
        position: absolute;
        width: 60px !important;
        height: 30px !important;
        top: -4px !important;
        left: -40px !important;`
      : `color: transparent;
        position: absolute;
        width: 30px !important;
        height: 30px !important;
        top: -4px !important;
        left: -40px !important;`};

&::before {
      ${({ placement }) =>
    placement === "right-start"
      ? `border-style: solid;
        border-width: 0px 60px 30px 0;;
        transform: rotate(0deg);
        border-color: transparent var(--b-color-main-1) transparent transparent;`
      : `border-style: solid;
        border-width: 15px 0 15px 15px;
        transform: rotate(0deg);
        border-color: transparent transparent transparent var(--b-color-additional-1);`};
    }
  }
`;

const BootstrapTooltipMobile = (parrentProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleTooltipClose = () => {
    setIsOpen(false);
  };

  const handleTooltipOpen = () => {
    setIsOpen((open) => !open);
  };

  const StyledMobileTooltip = styled(({ placement, ...props }) => (
    <Tooltip
      {...props}
      classes={{ popper: props.className }}
      open={isOpen}
      onClick={handleTooltipOpen}
      onClose={handleTooltipClose}
      disableFocusListener
      disableHoverListener
      disableTouchListener
    />
  ))`
    & .MuiTooltip-tooltip {
      padding: 20px 20px 20px;
      border-radius: 20px 20px 20px 20px;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      font-variant-numeric: lining-nums proportional-nums;
      background-color: ${({ placement }) => placement === "right-start" ? `var(--b-color-main-1)` : `var(--b-color-additional-1)`};
    }

    & .MuiTooltip-arrow {
      position: absolute;
      width: 30px !important;
      height: 20px !important;
      top: 10px !important;
      right: -15px !important;
      background-image: url(${TooltipArrowRight});
      background-repeat: no-repeat;
      background-size: contain;
      overflow: unset;

      &::before {
        display: none;
      }
    }
  `;

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <>
        <StyledMobileTooltip {...parrentProps} />
        {isOpen && (
          <div className="blurred-background" onClick={handleTooltipClose} />
        )}
      </>
    </ClickAwayListener>
  );
};

function CustomTooltip({
  name,
  tariffPremium,
  scrollable = false,
  sign = "Question",
  placement = "right-start",
}) {
  const { t } = useTranslation();
  const translatedText = t(name, { tariffPremium });

  return (
    <>
      {isMobile ? (
        <BootstrapTooltipMobile
          title={<TooltipContent text={translatedText} scrollable={scrollable} />}
          placement={placement === "right-start" ? "right-start" : placement}
        >
          <img
            src={sign === "Question" ? Question : Exclamation}
            width={isMobileOnly ? 20 : 24}
            height={isMobileOnly ? 20 : 24}
            className="tariff_details__item__right__tooltip"
          />
        </BootstrapTooltipMobile>
      ) : (
        <BootstrapTooltip
          title={<TooltipContent text={translatedText} scrollable={scrollable} />}
          placement={placement === "right-start" ? "right-start" : placement}
          // followCursor
          arrow
        >
          <img
            src={sign === "Question" ? Question : Exclamation}
            width={isMobileOnly ? 20 : 24}
            height={isMobileOnly ? 20 : 24}
            className="tariff_details__item__right__tooltip"
          />
        </BootstrapTooltip>
      )}
    </>
  );
}

export default CustomTooltip;
