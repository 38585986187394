import { useState, useEffect } from "react";

export const useProjectTheme = () => {
  const [theme, setTheme] = useState(document.body.getAttribute("data-theme"));

  useEffect(() => {
    const handleThemeChange = () => {
      setTheme(document.body.getAttribute("data-theme"));
    };

    const observer = new MutationObserver((mutationsList) => {
      if (
        mutationsList.some(
          (mutation) => mutation.attributeName === "data-theme"
        )
      ) {
        handleThemeChange();
      }
    });

    observer.observe(document.body, {
      attributes: true,
    });

    return () => observer.disconnect();
  }, []);

  return theme;
};
