import React, { useEffect, useState } from "react";
import Loading from "../../../components/common/Loading";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getToken } from "../../../config/token";
import { Request } from "../../../config/requests";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Select, MenuItem, FormControl, InputLabel, TextField } from "@mui/material";
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import { showToast } from "../../../config/helpers";


function AuditPrevention() {
  const { t } = useTranslation();
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isStatisticLoading, setIsStatisticLoading] = useState(false);
  const [preventions, setPreventions] = useState({ "claims": [] });
  const [comment, setComment] = useState("");
  const [preventionsStatistics, setPreventionsStatistics] = useState([]);

  function getPreventions() {
    setIsLoading(true);
    Request({
      method: "get",
      route: "api/claims/preventions",
      token: getToken(),
    })
      .then((res) => {
        return res.json();
      })
      .then(async (json) => {
        setPreventions(json.data || null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function getStatistics() {
    setIsStatisticLoading(true);
    Request({
      method: "get",
      route: "api/claims/preventions/statistics",
      token: getToken(),
    })
      .then((res) => {
        return res.json();
      })
      .then(async (json) => {
        setPreventionsStatistics([json.data.is_prev + json.data.not_prev + json.data.not_sure + json.data.mixed_bill, json.data.total])
      })
      .finally(() => {
        setIsStatisticLoading(false);
      });
  }

  function handleConfirm() {
    setIsConfirming(true);
    Request({
      method: "post",
      route: "api/claims/preventions_status",
      token: getToken(),
      values: {
        "id": preventions.id,
        "prevention_status": preventions.preventions,
        "comment": comment,
      }
    })
      .then((res) => {
        return res.json();
      })
      .then(() => {
        showToast(t("confirmed"), { position: "top-center" });
        getPreventions();
        getStatistics();
        setComment("");
        setIsConfirming(false);
      });
  }

  function handleUpdate(index, key, arr) {
    let tempPreventions = [...preventions.preventions];
    tempPreventions[index][key] = arr;
    setPreventions({ ...preventions, preventions: tempPreventions });
  }

  useEffect(() => {
    getPreventions();
    getStatistics();
    console.log("calling Preventions...");
  }, []);

  return (
    <div className="FMContainer">
      <div className="AuditContent">
        <div className="DuplicateWrap">
          <div className="HeaderContainerDuplicates">
            <div className="HeaderDuplicates">
              {t("auditPrevention")}
            </div>
            {!isStatisticLoading && (
              <div className="CountContainer">
                {preventionsStatistics[1] - preventionsStatistics[0]} {t("isOfPending")} {preventionsStatistics[1]}
              </div>
            )}
            <div
              className="ButtonReverse Enabled"
              onClick={() => history("/audit")}>
              {t("auditClaimsBack")}
            </div>
          </div>
          {isLoading
            ? <Loading noText fast />
            : (<div className="PreventionDocWrap">
              {preventions === null
                ? <h2 className="DuplicateFinish">{t("preventionEmpty")}</h2>
                : <>
                  <div className="PdfDoc">
                    <ReactPanZoom
                      image={`data:image/jpeg;base64, ${preventions?.claims[0]?.content}`}
                      alt="Image alt text"
                    />
                  </div>
                  <div className="NavContainer">
                    {preventions && (
                      <div style={{ overflowY: 'scroll' }}>
                        <TableContainer>
                          <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                {[
                                  "item_one",
                                  "firstTrigger",
                                  "secondTrigger",
                                  "Category",
                                  "prevention",
                                ].map((h) => (
                                  <TableCell key={h} className="HeaderTableQuoteDetails" align="left">
                                    {t(h)}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {preventions.preventions?.map((row, index) => (
                                <TableRow
                                  key={row.index}
                                  sx={{
                                    verticalAlign: "top",
                                    "&:last-child td, &:last-child th": { border: 0 },
                                  }}
                                >
                                  <TableCell key={1}>
                                    {row.item.Description.content}
                                  </TableCell>
                                  <TableCell key={2}>
                                    {![null, "nan", ""].includes(row.first_trigger) ? row.first_trigger : t("noTrigger")}
                                  </TableCell>
                                  <TableCell key={3}>
                                    {![null, "nan", ""].includes(row.second_trigger) ? row.second_trigger : t("noTrigger")}
                                  </TableCell>
                                  <TableCell key={4}>
                                    {![null, "nan", ""].includes(row.category) ? row.category : t("noCategory")}
                                  </TableCell>
                                  <TableCell key={5}>
                                    <FormControl
                                      key="tc-5"
                                      sx={{ minWidth: 150 }}
                                      size="small"
                                    >
                                      <InputLabel
                                        id="prev-label"
                                        sx={{ background: "white" }}
                                      >
                                        {t("preventionStatus")}
                                      </InputLabel>
                                      <Select
                                        labelId="prev-label"
                                        id="prev-name"
                                        value={row.prevention_status || null}
                                        onChange={(e) => handleUpdate(index, "prevention_status", e.target.value)}
                                      >
                                        {["yes", "no", "notSure"].map((item, idx) => (
                                          <MenuItem
                                            key={`item-ps-${idx}`}
                                            value={item}
                                          >
                                            {t(item)}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>)}
                    <div>
                      <TextField
                        multiline
                        label={`${t("comment")}*`}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        sx={{ width: "100%" }}
                        maxRows={4}
                      />
                    </div>
                    {isConfirming
                      ? <Loading noText fast />
                      : <button
                        className="ButtonReverse Enabled"
                        onClick={handleConfirm}>
                        {t("confirm")}
                      </button>
                    }
                  </div>
                </>
              }
            </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default AuditPrevention;
