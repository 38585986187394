import { useEffect, useRef } from "react";
import { isMobileOnly } from "react-device-detect";

function QuestionContainer({ children, width }) {
  const container = useRef(null);

  useEffect(() => {
    return () => {
      container.current = null;
    };
  }, []);

  return (
    <div
      className={(width !== "Half width" || isMobileOnly) ? "QuestionContainer" : "HalfQuestionContainer"}
      ref={container}
    >
      {children}
    </div>
  );
}

export default QuestionContainer;
