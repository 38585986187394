import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Account from "../Account";
import Claims from "../claims/Claims";
import ContactClaim from "../ContactClaim";
import FlowSteps from "../flowManagement/FlowSteps";
import Insurances from "../insurances/Insurances";
import PetPage from "../petPage/PetPage";
import Login from "../Login";
import Management from "../Management";
import Audit from "../Audit";
import AuditDuplicated from "../auditClaims/auditDuplicated/AuditDuplicated.js";
import RequireAuth from "./RequireAuth";
import Settings from "../Settings";
import MainLayout from "../../components/common/MainLayout";
import AuditOcr from "../auditClaims/auditOcr/AuditOcr";
import DropdownPage from "../dropdown/DropdownPage";
import AuditPrevention from "../auditClaims/auditPrevention/auditPrevention.js";
import Page404 from "./Page404";
import FlowTranslations from "../flowManagement/FlowTranslations";
import FlowCompleted from "../../components/common/FlowCompleted.js";
import ReadClaim from "../auditClaims/readClaim/ReadClaim.js";
import PaymentPage from "../auditClaims/paymentPage/PaymentPage.js";

const router = createBrowserRouter([
  {
    path: "/*",
    element: (
      <MainLayout>
        <RequireAuth>
          <Page404 />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: "/",
    element: (
      <MainLayout>
        <RequireAuth>
          <Insurances />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: "/pet/:id",
    element: (
      <MainLayout>
        <RequireAuth>
          <PetPage />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: "/account",
    element: (
      <MainLayout>
        <RequireAuth>
          <Account />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: "/claims",
    element: (
      <MainLayout>
        <RequireAuth>
          <Claims />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: "/contact/:id/claim",
    element: (
      <MainLayout>
        <RequireAuth>
          <ContactClaim />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: "/management",
    element: (
      <MainLayout>
        <RequireAuth>
          <Management />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: "/dropdown",
    element: (
      <MainLayout>
        <RequireAuth>
          <DropdownPage />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: "/audit",
    element: (
      <MainLayout>
        <RequireAuth>
          <Audit />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: "/audit-duplicated",
    element: (
      <MainLayout>
        <RequireAuth>
          <AuditDuplicated />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: "/audit-prevention",
    element: (
      <MainLayout>
        <RequireAuth>
          <AuditPrevention />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: "/audit-ocr",
    element: (
      <MainLayout>
        <RequireAuth>
          <AuditOcr />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: "/read-claim",
    element: (
      <MainLayout>
        <RequireAuth>
          <ReadClaim />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: "/payment-page",
    element: (
      <MainLayout>
        <RequireAuth>
          <PaymentPage />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: "/management/create",
    element: (
      <MainLayout>
        <RequireAuth>
          <FlowSteps />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: "/management/translations",
    element: (
      <MainLayout>
        <RequireAuth>
          <FlowTranslations />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: "/management/edit/:flow_id",
    element: (
      <MainLayout>
        <RequireAuth>
          <div className="flow-steps-container">
            <FlowSteps />
          </div>
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: "/settings",
    element: (
      <MainLayout>
        <RequireAuth>
          <Settings />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: "/login",
    element: (
      <MainLayout>
        <Login />
      </MainLayout>
    ),
  },
  {
    path: "/flow-completed",
    element: (
      <MainLayout>
        <FlowCompleted />
      </MainLayout>
    ),
  },
]);

export default router;
