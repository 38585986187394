import RedDownload from "../../../assets/iconsSmall/red_download.svg";
import Loading from "../../../components/common/Loading";
import { isMobile } from "react-device-detect";
import { formatAnyDate } from "../../../config/helpers";

export default function DocsDetails({
  docList,
  policyInd,
  downloadDocumentHandler,
  downloadingDocInd,
  downloadingPolicyInd
}) {

  return (
    <div className="tariff_details__item__right__info SlowShow">
      {docList.map((doc, docInd) => (
        <div
          key={`doc-${docInd}`}
          className="tariff_details__item__right__option"
        >
          <div
            className="tariff_details__item__right__info__line pointer"
            onClick={(e) => {
              e.stopPropagation();
              if (docInd !== downloadingDocInd && policyInd !== downloadingPolicyInd) {
                downloadDocumentHandler(doc, docInd, policyInd);
              }
            }}
          >
            {(docInd === downloadingDocInd && policyInd === downloadingPolicyInd)
              ? <div style={{ margin: "5px 10px 0 0" }}>
                <Loading noText fast scroll={false} size={isMobile ? 15 : 24} />
              </div>
              : <img className="square15" src={RedDownload} width={24} height={24} />}
            <div>
              <span>{formatAnyDate(doc?.file?.createdAt) + " " + doc?.file?.filename}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
} 
