import { Box, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useMemo, useState } from "react";
import FormButtonFM from "./FormButtonFM";
import QuestionContainer from "./QuestionContainer";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { incorrectDateRangePicked } from "../../../../config/helpers";
import useTranslations from "../../../../hooks/useTranslations";
import PetImage from "../../../common/components/PetImage";
import 'moment/locale/de';
import 'moment/locale/en-gb';
import i18n from "../../../../lang/i18n";

function DateRange({
  data,
  setData,
  details,
  showButtons = true,
  onUpdate,
  width,
  single = false,
}) {
  const { t } = useTranslation();
  const [date, setDate] = useState(
    details.from ? moment(details.from, "DD-MM-YYYY") : null
  );
  const [dateTwo, setDateTwo] = useState(
    details.to ? moment(details.to, "DD-MM-YYYY") : null
  );
  const { getTranslatedText } = useTranslations();
  const currentLanguage = i18n.language;

  if (currentLanguage === 'de') {
    moment.locale('de');
  } else if (currentLanguage === 'en') {
    moment.locale('en-gb');
  }

  const handleDate = (newValue, isSecond = false) => {
    if (isSecond) {
      setDateTwo(newValue);
      details["to"] = newValue?.format("DD-MM-YYYY");
      details["answer"] = `${date?.format("DD-MM-YYYY")}, ${newValue?.format("DD-MM-YYYY")}`
    } else {
      setDate(newValue);
      details["from"] = newValue?.format("DD-MM-YYYY");
      details["answer"] = `${newValue?.format("DD-MM-YYYY")}, ${dateTwo?.format("DD-MM-YYYY")}`
    }
    if (details.to && details.from) {
      let item = data.manual?.find((i) => i.name === details.title);
      if (item) {
        setData({
          ...data,
          manual: data.manual.map((i) =>
            i.name === details.title
              ? { ...i, value: `${details.from}, ${details.to}` }
              : i
          ),
        });
      } else {
        item = {
          name: details.title,
          value: `${details.from}, ${details.to}`,
        };
        setData({
          ...data,
          manual: [...data.manual, item],
        });
      }
    }
  };

  const invalidValues = useMemo(
    () => incorrectDateRangePicked(date, dateTwo),
    [date, dateTwo]
  );

  return (
    <QuestionContainer width={width}>
      <Box style={{ width: "100%" }}>
        <Box className="flow-item-wrapper flow-content-spacing ">
          <Box className="flow-item-container">
            <div
              className="TitleQuestion"
              style={{ padding: single ? "0 20px" : "" }}
            >
              {getTranslatedText(details.title)}
              {details.description && (
                <div className="TooltipGap">
                  <Tooltip
                    title={getTranslatedText(details.description)}
                    placement="right"
                  >
                    <InfoOutlinedIcon fontSize="small" />
                  </Tooltip>
                </div>
              )}
            </div>
            <div
              className="AnswersContainer"
              style={{ padding: single ? "0 20px" : "" }}
            >
              <div className="DateRange">
                <DatePicker
                  className="flow-date-picker"
                  value={date}
                  slotProps={{ textField: { placeholder: t("from") } }}
                  onChange={(newValue) => {
                    handleDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "48%" }} />
                  )}
                />
                <DatePicker
                  className="flow-date-picker"
                  value={dateTwo}
                  slotProps={{ textField: { placeholder: t("to") } }}
                  onChange={(newValue) => {
                    handleDate(newValue, true);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "48%" }} />
                  )}
                />
              </div>
              {invalidValues && date && dateTwo && (
                <Box sx={{ width: "100%", textAlign: "left" }}>
                  <span className="error-color">{t("dateRangeError")}</span>
                </Box>
              )}
            </div>
            {showButtons && (
              <div
                className="NavBtnsContainer"
                style={{ padding: single ? "0 20px" : "" }}
              >
                <FormButtonFM
                  name="Back"
                  route="back"
                />
                <FormButtonFM
                  name="Next"
                  route="next"
                  data={data}
                  onUpdate={onUpdate}
                  disabled={!date || !dateTwo || invalidValues}
                />
              </div>
            )}
          </Box>
          {single && <PetImage />}
        </Box>
      </Box>
    </QuestionContainer>
  );
}

export default DateRange;
