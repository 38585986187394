import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Datepicker from "../../../components/common/components/Datepicker";
import { isMobileOnly } from "react-device-detect";
import ModalWrapper from "../../../components/modalWrapper";
import moment from "moment";

function ModalEditPD({
  openModalEditPD,
  setOpenModalEditPD,
  outerDialog = "main",
  submitPD,
}) {
  const { t } = useTranslation();
  const [dialog, setDialog] = useState(outerDialog);
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [confirmEmail, setConfirmEmail] = useState("");
  const [date, setDate] = useState(null);
  const headerLabels = {
    "main": t("changeData"),
    "changeBD": t("changeBD"),
    "changeEmail": t("changeEmail"),
    "confirm": t("changeData"),
  }

  const handleEmail = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(inputValue);
    setIsValidEmail(isValid);
  };

  function handleClose() {
    setOpenModalEditPD(false);
  }

  return (

    <ModalWrapper
      headerTitle={headerLabels[dialog]}
      openModal={openModalEditPD}
      setModalWrapper={() => setOpenModalEditPD(false)}
    >
      {dialog === "main" && (
        <>
          <div className="modal_container__body__text">
            {t("whatChange")}
          </div>
          <div className="modal_container__body__buttons">
            <div className="modal_container__body__button" onClick={() => setDialog("changeEmail")}>{t("changeEmail")}</div>
            <div className="modal_container__body__button" onClick={() => setDialog("changeBD")}>{t("changeBD")}</div>
          </div>
          <div className={`button_red_small ${isMobileOnly && "mobile"}`}>
            {t("Next")}
          </div>
        </>)}

      {dialog === "changeBD" && (
        <>
          <div className="modal_container__body__text">
            {t("enterBD")}
          </div>
          <div className="modal_container__body__dropdowns">
            <Datepicker
              date={date}
              setDate={(newValue) => setDate(newValue)}
              disabled={false}
              type={"reset-dp-modal"}
            />
          </div>
          <div
            className={
              (date !== null)
                ? "button_red_small"
                : "button_red_smal_disabled"
            }
            onClick={
              (date !== null)
                ? (() => {
                  submitPD("birthDay", moment(date).format('YYYY-MM-DD'));
                  setDialog("confirm");
                })
                : ((e) => e.stopPropagation())
            }>
            {t("Next")}
          </div>
        </>)}

      {dialog === "changeEmail" && (
        <>
          <div className="modal_container__body__text">
            {t("enterEmail")}
          </div>
          <div className="modal_container__body__inputs">
            <input
              className="modal_container__body__input"
              style={isValidEmail ? {} : { border: "3px solid red" }}
              placeholder={t("yourEmail") + "*"}
              value={email}
              onChange={handleEmail}
            />
            {!isValidEmail &&
              <div className="modal_container__body__error FastShow">
                {t("emailWrong")}
              </div>}
            <input
              className="modal_container__body__input"
              placeholder={t("confirmEmail") + "*"}
              value={confirmEmail}
              onChange={(e) => setConfirmEmail(e.target.value)}
            />
            {((email.length === confirmEmail.length) && (email !== confirmEmail)) &&
              <div className="modal_container__body__error FastShow">
                {t("confirmEmailWrong")}
              </div>}
          </div>
          <div
            className={
              (isValidEmail && (email === confirmEmail) && email.length)
                ? "button_red_small"
                : "button_red_smal_disabled"
            }
            onClick={
              (isValidEmail && (email === confirmEmail) && email.length)
                ? (() => {
                  submitPD("email", email);
                  setDialog("confirm");
                })
                : ((e) => e.stopPropagation())
            }>
            {t("Next")}
          </div>
        </>)}

      {dialog === "confirm" && (
        <>
          <div className="modal_container__body__inputs">
            <div
              className="modal_container__body__text"
              style={{ textAlign: "left" }}
            >
              {t("confirmChangeData_1")}
              <br />
              <br />
              {t("confirmChangeData_2")}
              <br />
              <br />
              {t("confirmChangeData_3")} &#x1F60A;
            </div>
          </div>
          <div
            className="button_red_small"
            onClick={() => {
              handleClose();
            }}>
            OK
          </div>
        </>)}
    </ModalWrapper>
  );
}

export default ModalEditPD;
