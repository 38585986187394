import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateUser } from '../config/actions';

export const useUserInfo = () => {
  const dispatch = useDispatch();

  const fetchUserInfo = useCallback(async (curToken) => {
    try {
      const response = await fetch("/api/users/me", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${curToken}`,
        },
      });
      const json = await response.json();

      if (response.ok) {
        dispatch(updateUser(json.data));
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  return { refetch: fetchUserInfo };
};
