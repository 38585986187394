import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  updateFlowCurrentStep,
  updateWtdModalOpen,
} from "../../../../config/actions";
import { useTranslation } from "react-i18next";
import { FMConstants } from "../../../../constants/Forms.js";
import useCompleteFlow from "../../../../hooks/useCompleteFlow.js";
import { isMobileOnly } from "react-device-detect";
import { FLOW_TYPES } from "../../../../constants/Flow.js";

function FormButtonFM({
  name,
  route,
  disabled,
  onUpdate
}) {
  const history = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentStep = useSelector((state) => state.flow.flowCurrentStep);
  const flowQuestions = useSelector((s) => s.flow.flowQuestions);
  const flowType = useSelector((s) => s.flow.flowType);
  const selectedFlowType = useSelector((s) => s.flow.selectedFlowTypeToSkip);
  const claimIDlist = useSelector((s) => s.flow.claimIDlist);
  const { compleFlow } = useCompleteFlow();
  const [inprogress, setInprogress] = useState(false);

  const isLastQuestion = flowQuestions.length - 1 === currentStep;
  const lastQuestion = flowQuestions[currentStep];


  const onBackClicked = () => {
    if (currentStep < 1 || (currentStep === 1 && flowType === "Existing Claim" && selectedFlowType !== "")) {
      history(`/`);
      dispatch(updateWtdModalOpen(true));
    } else {
      dispatch(updateFlowCurrentStep(currentStep - 1));
    }
  };

  const onNextClicked = async () => {
    await onUpdate(currentStep + 1);
  };

  async function onClickHandler() {
    if (disabled || inprogress) return;
    setInprogress(true);
    if (isLastQuestion && lastQuestion[0].type !== "mcq" && route !== "back") {
      const isLastStep = currentStep === flowQuestions.length - 1;
      const isLastStepUploadOcr =
        lastQuestion[0]?.type === FMConstants.UPLOAD &&
        lastQuestion[0]?.content?.ocr;

      if (lastQuestion[0].type === "upload" || lastQuestion[0].type === "payment") {
        await onNextClicked();
      }

      if (isLastStep && isLastStepUploadOcr) {
        // for last step OCR modals are shown
      } else {
        setTimeout(() => {
          try {
            if (flowType === FLOW_TYPES["Existing Claim"]) {
              compleFlow(claimIDlist);
            } else {
              compleFlow();
            }
          }
          catch {
            console.error("FormButtonFM > onClickHandler - caught some error!")
          }
          finally {
            setInprogress(false);
          }
        }, 2000);
      }
      return;
    }

    if (route === "next") {
      onNextClicked();
    } else {
      onBackClicked();
    }
  }

  return (
    <div
      className={`${isMobileOnly ? "button_red_mobile" : "button_red_common"} ${disabled ? "disabled" : ""}`}
      style={{ minWidth: isMobileOnly ? '120px' : '180px' }}
      onClick={onClickHandler}
    >
      <div>{t(name)}</div>
    </div>
  );
}

export default FormButtonFM;
