import CustomTooltip from "../../../components/ui/CustomTooltip";
import RedCheck from "../../../assets/iconsSmall/red_check.svg";
import { useTranslation } from "react-i18next";

export default function CheckDetails({ tariff, selectedPackage, available }) {
  const { t } = useTranslation();

  return (
    <div className="tariff_details__item__right__info SlowShow">
      {tariff[selectedPackage].additional_info[available]
        ? tariff[selectedPackage].additional_info[available].map((option, ind) => (
          <div className="tariff_details__item__right__option" key={ind}>
            <div className="tariff_details__item__right__info__line">
              <img src={RedCheck} width={24} height={18} />
              <div>
                <span>{t(option.title)}</span>
                {option.tooltip &&
                  <CustomTooltip
                    name={option.tooltip}
                  />}
                {option.children &&
                  <div className="tariff_details__item__right__info__ul">
                    <ul>
                      {option.children.map((child, ind) => (
                        <li key={ind}>
                          {t(child.content)}
                          {child.tooltip &&
                            <CustomTooltip
                              name={child.tooltip}
                            />}
                        </li>
                      ))}
                    </ul>
                  </div>}
              </div>
            </div>
          </div>
        ))
        : t("noInfo")}
    </div>
  );
} 
