import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isMobileOnly } from "react-device-detect";
import { showToast } from "../../../config/helpers";
import ModalWrapper from "../../../components/modalWrapper";
import RadioSelected from "../../../assets/iconsSmall/radio_selected.svg";
import RadioUnselected from "../../../assets/iconsSmall/radio_unselected.svg";
import { Request } from "../../../config/requests";

function ModalFormEmail({
  openModalFormEmail,
  setOpenModalFormEmail,
}) {
  const userInfo = useSelector((s) => s.form.user);
  const { t } = useTranslation();
  const [name, setName] = useState(userInfo?.firstName + " " + userInfo?.lastName);
  const [email, setEmail] = useState(userInfo?.email);
  const [message, setMessage] = useState("");
  const [messageTouched, setMessageTouched] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [selectedEmail, setSelectedEmail] = useState(null);

  const handleEmail = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(inputValue);
    setIsValidEmail(isValid);
  };

  function handleClose() {
    setOpenModalFormEmail(false);
  }

  async function handleSubmit() {
    Request({
      method: "post",
      route: "api/report/report-message",
      values: {
        email: email,
        name: name,
        message: message,
        contact: selectedEmail
      },
    })
      .then(res => {
        if (!res.ok) throw new Error(`Request failed with status ${res.status}`);
        showToast(t("emailConfirmed"));
      })
      .catch((err) => {
        showToast(t(err.message || "somethingWentWrong"), { error: true });
      })
      .finally(() => {
        handleClose();
      });
  }

  return (
    <ModalWrapper
      headerTitle={t("sendEmail")}
      openModal={openModalFormEmail}
      setModalWrapper={() => setOpenModalFormEmail(false)}
    >
      <div>{t("sendUsEmail")}
        <span style={{ color: `var(--b-color-main-1)` }}>*</span>
      </div>
      <div className="modal_container__body__inputs">
        <div
          className={`modal_container__body__radio-btn ${selectedEmail === "Kundenservice" ? "selected" : ""}`}
          onClick={() => setSelectedEmail("Kundenservice")}
        >
          <img src={selectedEmail === "Kundenservice" ? RadioSelected : RadioUnselected} alt="Radio-button" />
          {t("Kundenservice")}
        </div>
        <div
          className={`modal_container__body__radio-btn ${selectedEmail === "Leistungsfallabteilung" ? "selected" : ""}`}
          onClick={() => setSelectedEmail("Leistungsfallabteilung")}
        >
          <img src={selectedEmail === "Leistungsfallabteilung" ? RadioSelected : RadioUnselected} alt="Radio-button" />
          {t("Leistungsfallabteilung")}
        </div>
      </div>
      <div className="modal_container__body__inputs">
        <input
          className="modal_container__body__input"
          style={{ border: name.length ? "" : "3px solid red" }}
          placeholder={t("yourName") + "*"}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          className="modal_container__body__input"
          style={{ border: isValidEmail ? "" : "3px solid red" }}
          placeholder={t("email") + "*"}
          value={email}
          onChange={handleEmail}
        />
        <textarea
          className="modal_container__body__input"
          style={{ height: isMobileOnly ? "130px" : "230px", border: message.length || !messageTouched ? "" : "3px solid red" }}
          placeholder={t("message") + "*"}
          value={message}
          onClick={() => setMessageTouched(true)}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      <div style={{ margin: "0 0 20px 0" }}>{t("replyAsap")}</div>
      <button
        className={`button_red_small ${isMobileOnly && "mobile"}`}
        disabled={!(name && email && message && isValidEmail && selectedEmail)}
        onClick={handleSubmit}
      >
        {t("send")}
      </button>
      {(!isValidEmail || !name.length || (!message.length && messageTouched)) &&
        <div className="modal_container__body__error" style={{ margin: "10px 0 0 0" }}>
          {t("pleaseFillInputs")}
        </div>}
    </ModalWrapper>
  );
}

export default ModalFormEmail;
