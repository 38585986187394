import React from "react";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import NewUpload from "./new-upload";

export default function ContactClaim() {
  return (
    <div className="main-container">
      <Header />
      <div className="page-content">
          <div className="claim__content">
            <NewUpload />
          </div>
      </div>
      <Footer />
    </div>
  );
}
