import { FMConstants } from "../../constants/Forms";
import Date from "./Forms/FlowManagement/Date";
import DateRange from "./Forms/FlowManagement/DateRange";
import MCQ from "./Forms/FlowManagement/MCQ";
import OQ from "./Forms/FlowManagement/OQ";
import Status from "./Forms/FlowManagement/Status";
import Upload from "./Forms/FlowManagement/Upload";
import DropdownQuestion from "./Forms/FlowManagement/Dropdown";
import Type from "./Forms/FlowManagement/Type";

// FORMS
import InvoiceTable from "../../features/ocr/invoiceTable/InvoiceTable";
import ExistingClaim from "./Forms/FlowManagement/ExistingClaim";
import Payment from "./Forms/FlowManagement/Payment";

// FLOW MANAGEMENT

export const FMSwitch = ({
  data,
  setData,
  type,
  details,
  isActive,
  showButtons = true,
  claimId,
  ocrDetails = {},
  gotDetails = {},
  updateClaim,
  handleInput,
  index,
  onUpdate,
  width,
  single,
  ocrKey,
}) => {
  if (!isActive) return null;

  switch (type) {
    case FMConstants.TYPE:
      return Type({
        data,
        setData,
        details,
        showButtons,
        onUpdate,
        width,
        single,
      });
    case FMConstants.DATE:
      return (
        <Date
          data={data}
          setData={setData}
          details={details}
          showButtons={showButtons}
          onUpdate={onUpdate}
          width={width}
          single={single}
        />
      );
    case FMConstants.OQ:
      return OQ({
        data,
        setData,
        details,
        showButtons,
        onUpdate,
        width,
        single,
      });
    case FMConstants.MCQ:
      return MCQ({
        data,
        setData,
        details,
        showButtons,
        width,
        onUpdate,
      });
    case FMConstants.DATE_RANGE:
      return DateRange({
        data,
        setData,
        details,
        showButtons,
        onUpdate,
        width,
        single,
      });
    case FMConstants.STATUS:
      return Status({
        data,
      });
    case FMConstants.UPLOAD:
      return Upload({ data, setData, details, showButtons, onUpdate, updateClaim });
    case FMConstants.DROPDOWN:
      return DropdownQuestion({
        data,
        setData,
        details,
        showButtons,
        onUpdate,
        width,
        single,
      });
    case FMConstants.INVOICE_TABLE:
      return InvoiceTable({
        data,
        claimIdTemp: claimId,
        ocrDetails,
        gotDetails,
        handleInput,
        details,
        index,
        ocrKey,
      });
    case FMConstants.EXISTING_CLAIM:
      return ExistingClaim({
        data,
        claimIdTemp: claimId,
        ocrDetails,
        gotDetails,
        handleInput,
        details,
        index,
        ocrKey,
      });
    case FMConstants.PAYMENT:
      return (
        <Payment
          data={data}
          setData={setData}
          onUpdate={onUpdate}
          index={index}
        />
      );

    default:
      break;
  }
};
