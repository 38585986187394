import {
  Input,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  invoiceTableHeaders,
  existingClaimsHeaders,
  getCurrencyFromInvoice,
  getTotalAmount,
  getCurrencyForTotal,
  formatAnyDate,
} from "../../../../config/helpers";
import { t } from "i18next";
import NotChecked from "../../../../assets/iconsSmall/check_not_selected.png";
import Checked from "../../../../assets/iconsSmall/check_selected.png";
import CheckedMark from "../../../../assets/iconsSmall/check_selected_mobile.png";
import SelectedDocument from "../../../../assets/iconsSmall/document.svg";
import ShowFileIcon from "../../../../assets/iconsSmall/document-white.svg";
import Plus from "../../../../assets/iconsSmall/add_line.svg";
import ResetChanges from "../../../../assets/iconsSmall/close_btn_dark-red.svg";
import ModalPreview from "../../../claims/modals/ModalPreview";

import { useTableScroll } from "../../../../hooks/useTableScroll";
import { useEffect, useState } from "react";
import { USER_MODE } from "../../../../constants/ApplicationConstants";

const TableRestyle = ({
  taxDetails,
  initialTableDetails,
  handleUpdate,
  editValues = false,
  editRows = false,
  showResultTable = false,
  rowSelectHandler,
  updatingIndex,
  setUpdatingIndex,
  selectedTableDetails,
  resultTableDetails,
  isOnlySelectable,
  handleNew,
  mode,
  isTableCalculated,
  resetRowChanges,
  deleteNewLine,
}) => {
  const { bodyRef, headerRef, btnRef, syncScroll, listOfItemsRef, recalculate, scrollToBottom } = useTableScroll();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    recalculate()
  }, [isTableCalculated]);

  const headerCells = isOnlySelectable
    ? existingClaimsHeaders
    : (mode === USER_MODE.ADMIN)
      ? invoiceTableHeaders
      : invoiceTableHeaders.filter(el => el.title !== "got");

  const rowClickHandler = (index) => {
    if (editRows) {
      rowSelectHandler(index);
    } else if (editValues) {
      setUpdatingIndex(index);
    }
    if (index + 1 === resultTableDetails.length) scrollToBottom();
  };

  const addLineHandler = () => {
    handleNew();
    scrollToBottom();
  };

  return (
    <div className={`invoice-table ${isOnlySelectable && "existing"}`}>
      {isModalOpen && (
        <ModalPreview
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          row={selectedRow}
        />
      )}

      <TableContainer
        className="reset-table-spacing table-reset-header"
        sx={{ margin: "0 0 -3px 0" }}
        ref={headerRef}
      >
        <Table
          className={`SlowShow reset-table-new ${editValues
            ? "modal-restyle-edit-values"
            : editRows
              ? "modal-restyle-edit-rows"
              : "modal-restyle"}`}
          sx={{
            minWidth: 900,
          }}
        >
          <TableHead>
            <TableRow
              className={`reset-table-new__header 
              ${editRows ? "edit-rows" : ""} 
              ${editValues ? "updating-row" : ""}`}
            >
              {(editRows || isOnlySelectable || editValues) &&
                <TableCell key="header-cell-0" className="cell-transparent"></TableCell>}
              {headerCells.map((header, index) => (
                <TableCell
                  key={`header-${index}`}
                  align={index > 0 ? "inherit" : "left"}
                >
                  <div>
                    <span>{t(header.title)}</span>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>

      <TableContainer
        className="reset-table-spacing"
        sx={{ maxHeight: 314 }}
        ref={bodyRef}
        onScroll={syncScroll}
      >
        <Table
          className={`SlowShow reset-table-new ${editValues
            ? "modal-restyle-edit-values"
            : editRows
              ? "modal-restyle-edit-rows"
              : "modal-restyle"}`}
          sx={{
            minWidth: 900,
          }}
          onMouseOut={(e) => {
            if (!e.currentTarget.contains(e.relatedTarget)) {
              setUpdatingIndex(null);
            }
          }}
        >
          <TableBody ref={listOfItemsRef}>
            {(showResultTable ? resultTableDetails : selectedTableDetails)?.map(
              (row, index) => {
                const reducedAmount = row?.claimPortalInfo?.amount;
                const rowAmount = reducedAmount
                  ? `${reducedAmount} ${getCurrencyFromInvoice(row)}`
                  : t("noInfo");

                return (
                  <>
                    {isOnlySelectable
                      ? (
                        <TableRow
                          key={`row-${index}`}
                          className={`reset-table-new__body inverted-colors ${editRows ? "edit-rows" : ""} color-modal 
                        ${editRows
                              ? row?.selected
                                ? "edit-selected"
                                : "edit-unselected"
                              : "no-pointer"
                            }`}
                          onClick={() => rowSelectHandler(index)}
                        >
                          <TableCell key={0} className="cell-transparent">
                            <img
                              src={row?.selected ? CheckedMark : NotChecked}
                              alt="not-checked"
                            />
                          </TableCell>
                          <TableCell key={1}>
                            <div>{formatAnyDate(row?.claimPortalInfo?.invoiceDate) || t("noInfo")}</div>
                          </TableCell>
                          <TableCell key={2}>
                            <div>
                              <span>
                                {row?.claimNo || t("noInfo")}
                              </span>
                            </div>
                          </TableCell>
                          <TableCell key={3}>
                            <div>
                              <span>
                                {row?.claimPortalInfo?.vendorName || t("noInfo")}
                              </span>
                            </div>
                          </TableCell>
                          <TableCell key={4}>
                            <div>
                              <span>{row?.claimPortalInfo?.claimStatus.toUpperCase()}</span>
                            </div>
                          </TableCell>
                          <TableCell key={5}>
                            <div>
                              <span>{rowAmount}</span>
                            </div>
                          </TableCell>
                          <TableCell key={6}>
                            <div>
                              {row?.documents &&
                                row?.documents.length > 0
                                ? (
                                  <Tooltip title={t("seeInitDoc")} placement="top">
                                    <div
                                      className={`back_icon_red ${row?.selected ? "back_icon_white" : ""
                                        }`}
                                      style={{ marginRight: "10px" }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedRow(row);
                                        setIsModalOpen(true);
                                      }}
                                    >
                                      <img
                                        src={
                                          row?.selected
                                            ? ShowFileIcon
                                            : SelectedDocument
                                        }
                                        style={{
                                          color: "#FFFAF0",
                                          top: "5px",
                                          position: "relative",
                                        }}
                                      />
                                    </div>
                                  </Tooltip>
                                ) : (
                                  <Tooltip title={t("seeInitDoc")} placement="top">
                                    <div
                                      className={`back_icon_red ${row?.selected ? "back_icon_white" : ""
                                        }`}
                                      style={{
                                        marginRight: "10px",
                                        opacity: "50%",
                                      }}
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <img
                                        src={
                                          row?.selected
                                            ? ShowFileIcon
                                            : SelectedDocument
                                        }
                                        style={{
                                          color: "#FFFAF0",
                                          top: "5px",
                                          position: "relative",
                                          opacity: "50%",
                                        }}
                                      />
                                    </div>
                                  </Tooltip>
                                )}
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow
                          key={`row-${index}`}
                          className={`reset-table-new__body color-modal 
                            ${editRows ? "edit-rows" : ""} 
                            ${editRows
                              ? row?.selected
                                ? "edit-selected"
                                : "edit-unselected"
                              : "no-pointer"
                            }
                            ${editValues ? "updating-mode" : ""}
                            ${(editValues &&
                              (index === updatingIndex ||
                                (row?.changedValues && Object.entries(row?.changedValues).length > 0) ||
                                row?.IsNewLine))
                              ? "updating-row FastShow" : ""}`}
                          onClick={() => rowClickHandler(index)}
                        >
                          {editRows && (
                            <TableCell
                              key={0}
                              className="cell-transparent"
                            >
                              <img
                                src={row?.selected ? Checked : NotChecked}
                                alt="not-checked"
                              />
                            </TableCell>
                          )}
                          {editValues && (
                            <TableCell
                              key={0}
                              className="cell-transparent"
                            >
                              {((row?.changedValues && Object.entries(row?.changedValues).length > 0) ||
                                row?.IsNewLine) &&
                                <img
                                  className="reset-delete-line"
                                  src={ResetChanges}
                                  alt="Reset changes"
                                  onClick={() => {
                                    row?.IsNewLine
                                      ? deleteNewLine(index)
                                      : resetRowChanges(index)
                                  }}
                                />}
                            </TableCell>
                          )}
                          <TableCell
                            key={1}
                            style={(editValues || editRows)
                              ? {}
                              : {
                                backgroundColor: row.IsNewLine
                                  ? "var(--b-color-additional-1)"
                                  : row.changedValues
                                    ? Object.keys(row.changedValues).includes("Date")
                                      ? "var(--b-color-additional-1)"
                                      : ""
                                    : "",
                              }}
                          >
                            <>
                              {editValues ? (
                                <div>
                                  {(index === updatingIndex ||
                                    (row?.changedValues && Object.entries(row?.changedValues).length > 0)) &&
                                    !row?.IsNewLine &&
                                    <div className="initial-value">
                                      {initialTableDetails[index]?.Date}
                                    </div>
                                  }
                                  <Input
                                    placeholder={t("datePlaceholder")}
                                    value={row?.changedValues?.Date || row?.Date}
                                    className="input-edit-tr"
                                    sx={{
                                      borderRadius: "10px 0 0 10px",
                                      color: (row?.changedValues?.Date || row?.IsNewLine)
                                        ? "var(--b-color-text-primary-1)"
                                        : "var(--b-color-additional-1)",
                                    }}
                                    align="center"
                                    disableUnderline
                                    onChange={(e) =>
                                      handleUpdate(index, "Date", e)
                                    }
                                  />
                                </div>
                              ) : (
                                <div>{row?.changedValues?.Date || row?.Date}</div>
                              )}
                            </>
                          </TableCell>
                          <TableCell
                            key={2}
                            style={(editValues || editRows)
                              ? {}
                              : {
                                backgroundColor: row.IsNewLine
                                  ? "var(--b-color-additional-1)"
                                  : row.changedValues
                                    ? Object.keys(row.changedValues).includes("Description")
                                      ? "var(--b-color-additional-1)"
                                      : ""
                                    : "",
                              }}
                          >
                            {editValues ? (
                              <div>
                                {(index === updatingIndex ||
                                  (row?.changedValues && Object.entries(row?.changedValues).length > 0)) &&
                                  !row?.IsNewLine &&
                                  <div className="initial-value">
                                    {initialTableDetails[index]?.Description}
                                  </div>
                                }
                                <Input
                                  placeholder={t("addDescr")}
                                  value={row?.changedValues?.Description || row?.Description}
                                  className="input-edit-tr"
                                  sx={{
                                    color: (row?.changedValues?.Description || row?.IsNewLine)
                                      ? "var(--b-color-text-primary-1)"
                                      : "var(--b-color-additional-1)",
                                  }}
                                  disableUnderline
                                  onChange={(e) =>
                                    handleUpdate(index, "Description", e)
                                  }
                                />
                              </div>
                            ) : (
                              <div>{row?.changedValues?.Description || row?.Description}</div>
                            )}
                          </TableCell>
                          {/* {mode === USER_MODE.ADMIN &&
                            <TableCell key={3}>
                              {editValues ? (
                                <div>
                                  {index === updatingIndex && 
                                  <div className="initial-value">
                                    {initialTableDetails[index].GOT}
                                  </div>
                                  }
                                  <CustomSelect
                                    variant="invoice-table-restyle"
                                    options={gotList}
                                    formatCreateLabel={(i) => i.label}
                                    placeholder={row?.GOT || "-"}
                                    value={row.GOT || ""}
                                    onChange={(e) =>
                                      handleUpdate(index, "GOT", e.value)
                                    }
                                    isClearable={false}
                                    isOpacity={
                                      initialTableDetails[index]?.["GOT"] !== row.GOT
                                        ? 1
                                        : editValues
                                          ? 0.5
                                          : 1
                                    }
                                  />
                                </div>
                              ) : (
                                <div>{row?.GOT}</div>
                              )}
                            </TableCell>} */}
                          <TableCell
                            key={4}
                            style={(editValues || editRows)
                              ? {}
                              : {
                                backgroundColor: row.IsNewLine
                                  ? "var(--b-color-additional-1)"
                                  : row.changedValues
                                    ? Object.keys(row.changedValues).includes("Quantity")
                                      ? "var(--b-color-additional-1)"
                                      : ""
                                    : "",
                              }}
                          >
                            {editValues ? (
                              <div>
                                {(index === updatingIndex ||
                                  (row?.changedValues && Object.entries(row?.changedValues).length > 0)) &&
                                  !row?.IsNewLine &&
                                  <div className="initial-value">
                                    {initialTableDetails[index]?.Quantity}
                                  </div>
                                }
                                <Input
                                  className="input-edit-tr"
                                  sx={{
                                    color: (row?.changedValues?.Quantity || row?.IsNewLine)
                                      ? "var(--b-color-text-primary-1)"
                                      : "var(--b-color-additional-1)",
                                  }}
                                  placeholder="-"
                                  value={row?.changedValues?.Quantity || row?.Quantity}
                                  type="number"
                                  disableUnderline
                                  onChange={(e) =>
                                    handleUpdate(index, "Quantity", e)
                                  }
                                />
                              </div>
                            ) : (
                              <div>{row?.changedValues?.Quantity || row?.Quantity}</div>
                            )}
                          </TableCell>

                          <TableCell
                            key={5}
                            style={(editValues || editRows)
                              ? {}
                              : {
                                backgroundColor: row.IsNewLine
                                  ? "var(--b-color-additional-1)"
                                  : row.changedValues
                                    ? Object.keys(row.changedValues).includes("UnitPrice")
                                      ? "var(--b-color-additional-1)"
                                      : ""
                                    : "",
                              }}
                          >
                            {editValues ? (
                              <div>
                                {(index === updatingIndex ||
                                  (row?.changedValues && Object.entries(row?.changedValues).length > 0)) &&
                                  !row?.IsNewLine &&
                                  <div className="initial-value">
                                    {parseFloat(initialTableDetails[index]?.UnitPrice).toFixed(2)}
                                  </div>
                                }
                                <Input
                                  className="input-edit-tr"
                                  sx={{
                                    color: (row?.changedValues?.UnitPrice || row?.IsNewLine)
                                      ? "var(--b-color-text-primary-1)"
                                      : "var(--b-color-additional-1)",
                                  }}
                                  placeholder="-"
                                  value={row?.changedValues?.UnitPrice || row?.UnitPrice}
                                  type="number"
                                  disableUnderline
                                  endAdornment={
                                    <InputAdornment position="start">
                                      {row?.Currency == "$" ? "$" : "€"}
                                    </InputAdornment>
                                  }
                                  onChange={(e) =>
                                    handleUpdate(index, "UnitPrice", e)
                                  }
                                />
                              </div>
                            ) : (
                              <div>
                                {parseFloat(row?.changedValues?.UnitPrice || row?.UnitPrice).toFixed(2) + (row?.Currency == "$" ? " $" : " €")}
                              </div>
                            )}
                          </TableCell>

                          <TableCell
                            key={6}
                            style={(editValues || editRows)
                              ? {}
                              : {
                                backgroundColor: row.IsNewLine
                                  ? "var(--b-color-additional-1)"
                                  : row.changedValues
                                    ? Object.keys(row.changedValues).includes("Tax")
                                      ? "var(--b-color-additional-1)"
                                      : ""
                                    : "",
                              }}
                          >
                            {editValues ? (
                              <div>
                                {(index === updatingIndex ||
                                  (row?.changedValues && Object.entries(row?.changedValues).length > 0)) &&
                                  !row?.IsNewLine &&
                                  <div className="initial-value">
                                    {parseFloat(initialTableDetails[index]?.Tax).toFixed()}
                                  </div>
                                }
                                <Input
                                  className="input-edit-tr"
                                  sx={{
                                    color: (row?.changedValues?.Tax || row?.IsNewLine)
                                      ? "var(--b-color-text-primary-1)"
                                      : "var(--b-color-additional-1)",
                                  }}
                                  placeholder="-"
                                  value={row?.changedValues?.Tax || row?.Tax}
                                  type="number"
                                  disableUnderline
                                  endAdornment={
                                    <InputAdornment position="start">%</InputAdornment>
                                  }
                                  onChange={(e) =>
                                    handleUpdate(index, "Tax", e)
                                  }
                                />
                              </div>
                            ) : (
                              <div
                                className={
                                  editRows
                                    ? row?.selected
                                      ? "CellAmountRed"
                                      : ""
                                    : "CellAmountRed"
                                }
                              >
                                {parseFloat(row?.changedValues?.Tax || row?.Tax || taxDetails.taxRate || 19).toFixed() + " %"}
                              </div>
                            )}
                          </TableCell>

                          <TableCell
                            key={7}
                            style={(editValues || editRows)
                              ? {}
                              : {
                                backgroundColor: row.IsNewLine
                                  ? "var(--b-color-additional-1)"
                                  : row.changedValues
                                    ? Object.keys(row.changedValues).includes("Amount")
                                      ? "var(--b-color-additional-1)"
                                      : ""
                                    : "",
                              }}
                          >
                            {editValues ? (
                              <div>
                                {(index === updatingIndex ||
                                  (row?.changedValues && Object.entries(row?.changedValues).length > 0)) &&
                                  !row?.IsNewLine &&
                                  <div className="initial-value">
                                    {parseFloat(initialTableDetails[index]?.Amount).toFixed(2)}
                                  </div>
                                }
                                <Input
                                  className="input-edit-tr"
                                  sx={{
                                    borderRadius: "0 10px 10px 0",
                                    color: (row?.changedValues?.Amount || row?.IsNewLine)
                                      ? "var(--b-color-text-primary-1)"
                                      : "var(--b-color-additional-1)",
                                  }}
                                  placeholder="-"
                                  value={row?.changedValues?.Amount || row?.Amount}
                                  type="number"
                                  disableUnderline
                                  endAdornment={
                                    <InputAdornment position="start">
                                      {row?.Currency == "$" ? "$" : "€"}
                                    </InputAdornment>
                                  }
                                  onChange={(e) =>
                                    handleUpdate(index, "Amount", e)
                                  }
                                />
                              </div>
                            ) : (
                              <div
                                className={
                                  editRows
                                    ? row?.selected
                                      ? "CellAmountRed"
                                      : ""
                                    : "CellAmountRed"
                                }
                              >
                                {parseFloat(row?.changedValues?.Amount || row?.Amount).toFixed(2) + (row?.Currency == "$" ? " $" : " €")}
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                  </>
                );
              }
            )}

            {!editRows && !editValues &&
              <TableRow
                key={`row-total`}
                className={`reset-table-new__body total no-pointer`}
              >
                <TableCell
                  key={0}
                  style={{
                    width: "unset",
                    paddingLeft: "20px",
                  }}
                  colSpan={3}
                >
                  <div style={{ alignItems: "flex-start" }}>
                    {t("subInvTotal")}
                  </div>
                </TableCell>
                <TableCell
                  key={1}
                  style={{ width: "unset" }}
                >
                  <div>{getTotalAmount(resultTableDetails, true) + " " + getCurrencyForTotal(resultTableDetails)}</div>
                </TableCell>
                <TableCell
                  key={2}
                  style={{ width: "unset" }}
                >
                </TableCell>
                <TableCell
                  key={3}
                  style={{ width: "unset" }}
                >
                  <div>{getTotalAmount(resultTableDetails) + " " + getCurrencyForTotal(resultTableDetails)}</div>
                </TableCell>
              </TableRow>}

            {showResultTable &&
              <TableRow
                key={`row-info`}
                className={`reset-table-new__body info no-pointer`}
                style={{ height: "33px" }}
              >
                <TableCell
                  key={0}
                  style={{ width: "unset" }}
                  colSpan={6}
                >
                  <div>
                    {t("customerChanged")}
                  </div>
                </TableCell>
              </TableRow>}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer
        className="reset-table-spacing table-reset-header"
        ref={btnRef}
        sx={{ margin: "-3px 0 0 0" }}
        onScroll={syncScroll}
      >
        {editValues &&
          <Table
            className="SlowShow reset-table-new modal-add-line-btn"
            sx={{ minWidth: 900 }}
          >
            <TableBody>
              <TableRow
                key={`row-add-line`}
                className={`reset-table-new__body ${editRows ? "edit-rows" : ""} cell-add-line `}
                onClick={() => addLineHandler()}
              >
                <TableCell key="add-line-0" className="cell-transparent"></TableCell>
                <TableCell key="add-line-1">
                  <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                    <img
                      src={Plus}
                      alt="Plus"
                    />
                    <div>{t("addLine")}</div>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>}
      </TableContainer>
    </div>
  );
};

export default TableRestyle;
