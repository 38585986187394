import React, { useEffect, useState } from "react";
import FormButtonFM from "./FormButtonFM";
import QuestionContainer from "./QuestionContainer";
import { useTranslation } from "react-i18next";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { Request } from "../../../../config/requests";
import Loading from "../../../common/Loading";
import IBAN from "iban";

function Payment({
  onUpdate,
  index
}) {
  const { t } = useTranslation();
  const { id } = useParams();
  const [isPaymentLoading, setIsPaymentLoading] = useState(true);
  const [ibanError, setIbanError] = useState(false);

  const [newPayment, setNewPayment] = useState({
    name: "",
    secondName: "",
    iban: "",
    checked: true,
  });
  const [previousPaypent, setPreviousPayment] = useState({
    name: "Name",
    secondName: "Second name",
    iban: "000000000000",
    checked: false,
    visible: false,
  });

  // output -> new files { ...accountHolder, iban } for endpoint UPDATE
  // remove update-claim from flow
  // type add existing claim type

  const getPaymentMethods = () => {
    setIsPaymentLoading(true);
    Request({
      method: "get",
      route: `api/users/last_payment?policy_id=${id}`,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        const previousPayment = res.data;

        if (previousPayment) {
          setPreviousPayment((prev) => ({
            ...prev,
            visible: true,
            checked: true,
            name: previousPayment?.accountHolder,
            iban: previousPayment?.iban,
          }));

          setNewPayment((prev) => ({
            ...prev,
            checked: false,
          }));
        }
      })
      .finally(() => {
        setIsPaymentLoading(false);
      });
  };

  const nextBtnHandler = async () => {
    const paymentData = previousPaypent.checked
      ? {
        paymentAccountHolder: previousPaypent.name,
        paymentSecondName: previousPaypent.secondName,
        paymentIban: previousPaypent.iban,
      }
      : {
        paymentAccountHolder: newPayment.name,
        paymentSecondName: newPayment.secondName,
        paymentIban: newPayment.iban,
      };

    onUpdate(index + 1, { paymentData });
  };

  useEffect(() => {
    getPaymentMethods();
  }, []);

  useEffect(() => {
    // Validate IBAN on change
    if (newPayment.checked) {
      setIbanError(newPayment.iban && !IBAN.isValid(newPayment.iban));
    }
  }, [newPayment]);

  const isNextBtnEnabled =
    previousPaypent.checked ||
    (newPayment.checked && newPayment.name && newPayment.secondName && newPayment.iban && !ibanError);

  return (
    <QuestionContainer>
      <Box style={{ width: "100%" }}>
        <Box className="payment-wrapper">
          <div className="invoice-table-title">
            <div className="invoice-table-title__title">{t("paymentTile")}</div>
          </div>
          <div
            className="invoice-table-message column-centered"
            style={{ margin: "5vh 0 0 0" }}
          >
            {t("paymentDescription")}
          </div>

          {isPaymentLoading ? (
            <Loading noText fast height={"500px"} />
          ) : (
            <FormGroup>
              <Box className="payment-cards__wrapper">
                {previousPaypent.visible && (
                  <label
                    className={`payment-cards__item payment-cards__item_previous ${previousPaypent.checked
                      ? "payment-cards__item_checked"
                      : ""
                      }`}
                    style={{
                      backgroundColor: previousPaypent.checked
                        ? "#E3767A"
                        : "#FFF9F0",
                    }}
                  >
                    <RadioGroup
                      className="payment-cards__radio"
                      name="payment-radio-group"
                    >
                      <FormControlLabel
                        label=""
                        control={
                          <Radio
                            onClick={() => {
                              setPreviousPayment((prev) => ({
                                ...prev,
                                checked: true,
                              }));
                              setNewPayment((prev) => ({
                                ...prev,
                                checked: false,
                              }));
                              setIbanError(false);
                            }}
                            checked={previousPaypent.checked}
                          />
                        }
                      />
                    </RadioGroup>
                    <div className="payment-cards__inputs">
                      <TextField
                        id="payment-name"
                        label="Name"
                        value={previousPaypent.name || t("noInfo")}
                        style={{ pointerEvents: "none" }}
                        variant="standard"
                        placeholder={t("firstName")}
                      />
                      <TextField
                        id="payment-second-name"
                        label="Second name"
                        value={previousPaypent.secondName || t("noInfo")}
                        style={{ pointerEvents: "none" }}
                        variant="standard"
                        placeholder={t("lastName")}
                      />
                      <TextField
                        id="payment-iban"
                        label="IBAN"
                        value={previousPaypent.iban || t("noInfo")}
                        style={{ pointerEvents: "none" }}
                        variant="standard"
                        placeholder="IBAN"
                      />
                    </div>
                  </label>
                )}

                <label
                  className={`payment-cards__item ${newPayment.checked ? "payment-cards__item_checked" : ""}`}
                  style={{
                    backgroundColor: newPayment.checked ? "#E3767A" : "#FFF9F0",
                  }}
                >
                  <RadioGroup
                    className="payment-cards__radio"
                    name="payment-radio-group"
                  >
                    <FormControlLabel
                      label=""
                      control={
                        <Radio
                          checked={newPayment.checked}
                          onClick={() => {
                            setPreviousPayment((prev) => ({
                              ...prev,
                              checked: false,
                            }));
                            setNewPayment((prev) => ({
                              ...prev,
                              checked: true,
                            }));
                          }}
                        />
                      }
                    />
                  </RadioGroup>
                  <div className="payment-cards__inputs">
                    <TextField
                      id="payment-name"
                      placeholder={t("firstName")}
                      value={newPayment.name}
                      onChange={(e) =>
                        setNewPayment((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }))
                      }
                      variant="outlined"
                    />
                    <TextField
                      id="payment-second-name"
                      placeholder={t("lastName")}
                      value={newPayment.secondName}
                      onChange={(e) =>
                        setNewPayment((prev) => ({
                          ...prev,
                          secondName: e.target.value,
                        }))
                      }
                      variant="outlined"
                    />
                    <TextField
                      id="payment-iban"
                      placeholder="IBAN"
                      value={newPayment.iban}
                      onChange={(e) =>
                        setNewPayment((prev) => ({
                          ...prev,
                          iban: e.target.value,
                        }))
                      }
                      error={ibanError}
                      variant="outlined"
                    />
                  </div>
                </label>
              </Box>
            </FormGroup>
          )}
          <div
            className="NavBtnsContainer"
            style={{ marginBottom: "20px" }}
          >
            <FormButtonFM
              name="Back"
              route="back"
            />
            <FormButtonFM
              name="Next"
              route="next"
              onUpdate={nextBtnHandler}
              disabled={!isNextBtnEnabled}
            />
          </div>
        </Box>
      </Box>
    </QuestionContainer>
  );
}

export default Payment;
