import React, { useCallback, useEffect, useState } from "react";
import Footer from "../../components/common/Footer";
import Header from "../../components/common/Header";
import { Request } from "../../config/requests";
import { getToken } from "../../config/token";
import PolicyTable from "./PolicyTable";
import ModalInfo from "./modals/ModalInfo";
import useLocalStorage from "use-local-storage";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { USER_MODE } from "../../constants/ApplicationConstants";
import { Box, FormControl, Input, InputLabel } from "@mui/material";
import PawUI from "../../components/ui/PawUI";
import Clear from "../../assets/iconsSmall/cross_grey.svg";
import Skeleton from "@mui/material/Skeleton";
import DatePicker from "react-multi-date-picker";
import i18n from "../../lang/i18n";
import { formatTableDate, german } from "../../config/helpers";
import PetSlider from "./PetSlider";
import ErrorModal from "../../components/common/errorModal";
import { isMobile, isMobileOnly } from "react-device-detect";
import InvoiceTableFilterSkeleton from "./skeletons/InvoiceTableFilterSkeleton";
import CustomSelect from "../../components/inputs/select/CustomSelect";

export default function Claims() {
  const token = getToken();
  const { t } = useTranslation();
  const mode = useSelector((s) => s.form.mode);
  const pets = useSelector((s) => s.flow.pets);
  const selectedPetInfo = useSelector((s) => s.flow.selectedPetInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [isItemsLoading, setIsItemsLoading] = useState(false);
  const [petLoading, setPetLoading] = useState(false);
  const [filterStatus, setFilterStatus] = useState(null);
  const [filterVendor, setFilterVendor] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [selectedPetId, setSelectedPetId] = useState("");
  const [selectedPetName, setSelectedPetName] = useState("");
  const [selectedPetIndex, setSelectedPetIndex] = useState(0);
  const [themeSetting] = useLocalStorage("theme", "panda");
  const [policyID, setPolicyID] = useState("");
  const [policyData, setPolicyData] = useState([]);
  const [policyItems, setPolicyItems] = useState([]);
  const [insuredItem, setInsuredItem] = useState([]);
  const [petData, setPetData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [currentClaimNo, setCurrentClaimNo] = useState(null);
  const [error, setError] = useState(false);


  function getClaimsHistory(targetId) {
    setIsLoading(true);
    Request({
      method: "get",
      route: `api/v2/claims/policy_claims/${targetId}`,
      token: token,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Request failed with status: " + res.status);
        }
      })
      .then(async (json) => {
        setInsuredItem(json.insuredItem);
        setStatuses(json.statuses?.map((i) => ({ label: i, value: i })) || []);
        setVendors(json.vetNames?.map((i) => ({ label: i, value: i })) || []);
        let dataWithoutNullID = json.data.filter(el =>
          el.claimPortalInfo &&
          el.claimPortalInfo.hasOwnProperty("invoiceDate") &&
          el.claimPortalInfo.hasOwnProperty("claimStatus"))
        setPolicyData(dataWithoutNullID || []);
        setFilteredData(dataWithoutNullID || []);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function getClaimByID(policyID) {
    setIsLoading(true);
    Request({
      method: "get",
      route: `api/v2/claims/policy_claims/${policyID}`,
      token: token,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Request failed with status: " + res.status);
        }
      })
      .then(async (json) => {
        setInsuredItem(json.insuredItem);
        setStatuses(json.statuses?.map((i) => ({ label: i, value: i })) || []);
        setVendors(json.vetNames?.map((i) => ({ label: i, value: i })) || []);
        let dataWithoutNullID = json.data.filter(el =>
          el.claimPortalInfo &&
          el.claimPortalInfo.hasOwnProperty("invoiceDate") &&
          el.claimPortalInfo.hasOwnProperty("claimStatus"))
        setPolicyData(dataWithoutNullID || []);
        setFilteredData(dataWithoutNullID || []);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function getPolicyItems(policyId) {
    setIsItemsLoading(true);

    Request({
      method: "get",
      route: `api/v2/claims/get_reserves/${policyId}`,
      token: token,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Request failed with status: " + res.status);
        }
      })
      .then(async (json) => {
        setPolicyItems(json.data);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setIsItemsLoading(false);
      });
  }

  useEffect(() => {
    if (mode !== USER_MODE.ADMIN) {
      if (selectedPetId !== "") {
        getClaimsHistory(selectedPetId);
      }
      else {
        setPolicyData([]);
      }
    }
  }, [selectedPetId]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (selectedPetInfo?.policyId) {
      setSelectedPetId(selectedPetInfo?.policyId || "");
      setSelectedPetName(selectedPetInfo?.nameOfPet || "");
      setSelectedPetIndex(pets.findIndex(el => el.policy.id === selectedPetInfo?.policyId) || 0);
    }
  }, []);

  useEffect(() => {
    setPetData(pets);
    if (mode !== USER_MODE.ADMIN) {
      if (pets?.length > 0) {
        setPetLoading(false);
      } else {
        setPetLoading(true);
      }
    }
  }, [pets]);

  useEffect(() => {
    if (currentClaimNo) getPolicyItems(currentClaimNo);
  }, [currentClaimNo]);

  const handlePolicyID = (e) => {
    const inputText = e.target.value.toUpperCase();
    if (inputText.length > 12) {
      setPolicyID(inputText.slice(0, 12));
    } else {
      setPolicyID(inputText);
    }
  };

  const handleFilter = useCallback(() => {
    if (!isLoading) {
      let newData = [...policyData];
      if (filterStatus) {
        newData = newData
          .filter(el => el.claimPortalInfo?.claimStatus)
          .filter(
            (item) =>
              item?.data?.claimStatus.toLowerCase() === filterStatus.value.toLowerCase()
          );
      }

      if (filterVendor) {
        newData = newData
          .filter(el => el.claimPortalInfo?.vendorName)
          .filter(
            (item) =>
              item?.claimPortalInfo?.vendorName.toLowerCase() ===
              filterVendor.value.toLowerCase()
          );
      }

      if (dateRange && dateRange.length === 2) {
        const [startDate, endDate] = dateRange;

        const adjustedEndDate = new Date(formatTableDate(endDate));
        adjustedEndDate.setHours(23, 59, 59, 999);

        const adjustedStartDate = new Date(formatTableDate(startDate));
        adjustedStartDate.setHours(0, 0, 0, 0);

        newData = newData.filter((item) => {
          const incidentDate = new Date(formatTableDate(item?.additionalProperties?.additionalProperties?.dateOfInvoice1
            || item.incidentDate));
          return (
            incidentDate >= adjustedStartDate && incidentDate <= adjustedEndDate
          );
        });
      }

      const dataFound = newData.length > 0;
      if (dataFound) {
        setFilteredData(newData);
      } else {
        setFilteredData([]);
      }
    }
  }, [dateRange, filterStatus, filterVendor, isLoading, policyData]);

  useEffect(() => {
    handleFilter();
  }, [filterStatus, dateRange, selectedPetId, filterVendor, handleFilter]);

  function simulateClickOutside() {
    const body = document.querySelector("body");
    body.click();
  }

  const handleDateRAnge = (dates) => {
    setDateRange(dates);
    if (dates.length === 2) {
      simulateClickOutside();
    }
  };

  const handleSetSelectedPet = (id, petName) => {
    if (petName === selectedPetName && id === selectedPetId) {
      setSelectedPetId("");
      setSelectedPetName("");
    } else {
      setSelectedPetId(id);
      setSelectedPetName(petName);
    }
  };

  return (
    <div className="main-container SlowShow">
      <Header />
      <div className="page-content relative claims-content">

        {/* Background paws */}

        {isMobile ? (
          <>
            <PawUI
              top="1vh"
              right="6vw"
              rotation="right"
              width="25vw"
              zIndex="0"
            />
            <PawUI
              top="53vh"
              left="-20vw"
              rotation="right"
              width="60vw"
              zIndex="0"
            />
            <PawUI
              bottom="-23vh"
              right="-27vw"
              rotation="left"
              width="70vw"
              zIndex="0"
            />
          </>
        ) : (
          <>
            <PawUI
              top="20vh"
              right="1vw"
              rotation="right"
              width="350px"
              zIndex="0"
            />
            <PawUI
              bottom="-10vh"
              left="20vw"
              rotation="left"
              width="150px"
              zIndex="0"
            />
            <PawUI
              top="10vh"
              left="20vw"
              rotation="left"
              width="250px"
              zIndex="0"
            />
          </>
        )}
        <div className="claims">

          {/* Title + Slider */}

          {mode !== USER_MODE.ADMIN && (
            <>
              {petLoading
                ? <Skeleton
                  variant="rounded"
                  style={
                    isMobile
                      ? { width: "150px", height: "30px", margin: "0 auto" }
                      : { width: "300px", height: "50px", margin: "0 auto" }
                  }
                />
                : <h1
                  className="headline1_red_title"
                  style={{
                    width: "100%",
                    textAlign: "center",
                    position: "relative",
                    zIndex: 5,
                  }}
                >
                  {selectedPetName
                    ? selectedPetName + `${i18n.language === "de" ? " " : "'s "}` + t("rechnungen")
                    : t("Rechnungen")}
                </h1>}

              <PetSlider
                petData={petData}
                petLoading={petLoading}
                handlePetClick={handleSetSelectedPet}
                selectedPetId={selectedPetId}
                selectedPetIndex={selectedPetIndex}
              />
            </>
          )}

          {/* Search for admin */}

          {mode === USER_MODE.ADMIN && (
            <div className="PolicyInput reset-input">
              <FormControl
                sx={{
                  padding: "0 !important",
                  height: "56px !important",
                  marginRight: "20px",
                }}
              >
                <InputLabel
                  id="demo-multiple-name-label-title"
                  sx={{ background: "white" }}
                >
                  {t("policyID")}
                </InputLabel>

                <Input
                  id="demo-multiple-name-title"
                  type="text"
                  onChange={(e) => handlePolicyID(e)}
                  value={policyID}
                  sx={{
                    margin: "0 !important",
                    height: "100% !important",
                    width: "150px",
                    border: "thin solid rgb(154, 154, 154)",
                    fontSize: "12px",
                    borderRadius: "14px",
                    padding: "0 15px",
                  }}
                  disableUnderline
                // InputProps={{ maxLength: 12 }}
                />
              </FormControl>
              <button
                className="button_red"
                style={{ minWidth: "150px", margin: "0 10px" }}
                disabled={policyID === ""}
                onClick={() => getClaimByID(policyID)}
              >
                {t("search")}
              </button>
            </div>
          )}

          {/* Filters */}

          {!petLoading && mode !== USER_MODE.ADMIN && (
            <div
              className="filter_row"
              style={
                isMobile
                  ? {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    position: "relative",
                    gap: "5px",
                  }
                  : filteredData.length < 1
                    ? {
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                      marginLeft: "auto",
                      gap: "5px",
                    }
                    : {
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      position: "relative",
                      marginLeft: "auto",
                      gap: "5px",
                    }
              }
            >

              {isMobileOnly && mode === USER_MODE.ADMIN && (
                <div className="claims-filters-flex">
                  {!isLoading && (
                    <Box
                      className="full-width"
                      sx={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                        // marginBottom: "40px",
                        position: "relative",
                        width: "100%",
                      }}
                    >
                      <div
                        className={"custom-daterange"}
                        style={{
                          position: "relative",
                          width: "100%",
                        }}
                      >
                        <DatePicker
                          format={"DD-MM-YYYY"}
                          onChange={(date, options) =>
                            handleDateRAnge(options.validatedValue)
                          }
                          value={dateRange}
                          placeholder={t("dateRangePlaceHolder")}
                          locale={i18n.language === "de" ? german : null}
                          onClose={() => true}
                          range
                          dateSeparator=" - "
                        />
                        {dateRange.length > 0 && (
                          <img
                            src={Clear}
                            className="custom-daterange__close"
                            onClick={() => setDateRange([])}
                          />
                        )}
                      </div>
                    </Box>
                  )}
                </div>
              )}

              <div className="claims-filters-flex">
                {!isMobileOnly && !isLoading && mode === USER_MODE.ADMIN && (
                  <Box
                    className="full-width"
                    sx={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <div
                      className={"custom-daterange"}
                      style={{ position: "relative" }}
                    >
                      <DatePicker
                        format={"DD-MM-YYYY"}
                        onChange={(date, options) =>
                          handleDateRAnge(options.validatedValue)
                        }
                        value={dateRange}
                        placeholder={t("dateRangePlaceHolder")}
                        locale={i18n.language === "de" ? german : null}
                        onClose={() => true}
                        range
                        dateSeparator=" - "
                      />
                      {dateRange.length > 0 && (
                        <img
                          src={Clear}
                          alt=""
                          className="custom-daterange__close"
                          onClick={() => setDateRange([])}
                        />
                      )}
                    </div>
                  </Box>
                )}

                {!isLoading && (
                  <CustomSelect
                    variant="claims-filter"
                    options={statuses}
                    formatCreateLabel={(i) => i}
                    placeholder={t("status")}
                    value={filterStatus}
                    onChange={(e) => setFilterStatus(e)}
                    isClearable={true}
                    className={"filter-select"}
                    classNamePrefix="filter-select"
                  />
                )}

                {!isLoading && (
                  <CustomSelect
                    variant="claims-filter"
                    options={vendors}
                    formatCreateLabel={(i) => i}
                    placeholder={t("vet")}
                    value={filterVendor}
                    onChange={(e) => setFilterVendor(e)}
                    isClearable={true}
                    className={"filter-select"}
                    classNamePrefix="filter-select"
                  />
                )}
              </div>
            </div>
          )}

          {/* Table */}

          {(isLoading || petLoading)
            ? <InvoiceTableFilterSkeleton
              mode={mode}
            />
            : <div className={`FullContainerReverse ${isMobile ? "Mobile" : ""} SlowShow`}>
              {filteredData?.length > 0 ? (
                <div
                  className={`${isMobile ? "policy_wrapper" : ""} relative`}
                  style={!isMobile ? { width: "100%" } : {}}
                >
                  <PolicyTable
                    policyData={filteredData}
                    setCurrentClaimNo={setCurrentClaimNo}
                    setOpenModalInfo={setOpenModalInfo}
                    mode={mode}
                  />
                </div>
              ) : (
                <div>
                  <div className="empty-icon">
                    <div
                      data-testid="empty-page-icon"
                      className="emptyPageIcon_image__iMxS2"
                    >
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="184"
                          height="116"
                          viewBox="0 0 184 116"
                          fill="none"
                          className="injected-svg"
                          data-src="/static/media/empty-page.df8095e3635d05901d3567c688a394e1.svg"
                          xlink="http://www.w3.org/1999/xlink"
                        >
                          <path
                            d="M92 115.146C142.81 115.146 184 106.203 184 95.1707C184 84.1385 142.81 75.1951 92 75.1951C41.1898 75.1951 0 84.1385 0 95.1707C0 106.203 41.1898 115.146 92 115.146Z"
                            fill="#F5F5F5"
                          ></path>
                          <path
                            d="M158.125 37.4127L128.955 4.5899C127.555 2.35263 125.511 1 123.358 1H60.6424C58.489 1 56.4449 2.35263 55.0447 4.58705L25.875 37.4155V63.7805H158.125V37.4127Z"
                            stroke="#D9D9D9"
                          ></path>
                          <path
                            d="M119.637 46.4616C119.637 41.8815 122.495 38.1004 126.04 38.0975H158.125V89.8543C158.125 95.9127 154.33 100.878 149.644 100.878H34.3562C29.67 100.878 25.875 95.9098 25.875 89.8543V38.0975H57.96C61.5049 38.0975 64.3626 41.8729 64.3626 46.453V46.5158C64.3626 51.0959 67.252 54.7943 70.794 54.7943H113.206C116.748 54.7943 119.637 51.0617 119.637 46.4816V46.4616V46.4616Z"
                            fill="#FAFAFA"
                            stroke="#D9D9D9"
                          ></path>
                          <div className="claims">
                            <div className="container"></div>
                          </div>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div data-testid="empty-page-message" className="empty-text">
                    {t("noClaims")}
                  </div>
                </div>
              )}
            </div>}
        </div>
      </div>
      <Footer />
      {error && (
        <ErrorModal
          openModal={error}
          setErrorModal={() => setError(!error)}
          isRedirect={false}
        />
      )}
      {openModalInfo && (
        <ModalInfo
          openModalInfo={openModalInfo}
          setOpenModalInfo={setOpenModalInfo}
          themeSetting={themeSetting}
          isItemsLoading={isItemsLoading}
          tableInfo={policyItems}
          petName={insuredItem?.nameOfPet}
        />
      )}
    </div>
  );
}
