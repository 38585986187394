import React from 'react'
import PawLeftBig from '../../assets/UserInfo/panda_foot_left_big.svg'
import PawRightBig from '../../assets/UserInfo/panda_foot_right_big.svg'

const PawUI = ({
  width = "300px",
  rotation = "left",
  top = "unset",
  bottom = "unset",
  left = "unset",
  right = "unset",
  zIndex = "0",
}) => {
  return (
    <div style={{
      zIndex: zIndex,
      position: "absolute",
      background: `url(${rotation === "left" ? PawLeftBig : PawRightBig}) no-repeat center`,
      backgroundSize: "contain",
      width,
      height: width,
      top,
      bottom,
      left,
      right,
    }}>
    </div>
  )
}

export default PawUI